@import "../constants/colors.css";

footer {
  width: 100%;
  /* height: 50px; */
  width: 100%;
  background: linear-gradient(
    270deg,
    var(--linear-primary) 0.69%,
    var(--linear-secondary) 100%
  );
}

footer .container .footer {
  display: flex;
  padding: 40px;
}

footer ul {
  padding-left: 0px;
}

footer ul li {
  list-style: none;
  padding-left: 0px;
}

footer ul li a {
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--white);
  display: block;
  padding-bottom: 20px;
}

footer .footer h4 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
  color: var(--white);
}

footer .footer .text {
  font-weight: 600;
  font-size: 17px;
  line-height: 36px;
  padding-left: 15px;
  color: var(--white);
  margin-top: 10px;
  margin-left: 15px;
}

.footer_bottom {
  background: var(--text-color);
  padding: 5px 0px;
}

.footer_bottom p {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: var(--white);
}

footer .footer_sec_2 {
  display: flex;
  flex-wrap: wrap;
}

footer .footer_sec_1 {
  width: 30%;
}

footer .footer_sec_2 {
  width: 55%;
}

footer .footer_sec_3 {
  width: 15%;
  display: flex;
  justify-content: flex-end;
}

.footer_sec_2 .footer_sec_22 {
  width: 33.33%;
}

/* media quarry   vikashjdasd ad k*/

@media screen and (max-width: 1200px) {
  footer .container {
    padding: 0;
  }
  footer li a {
    font-size: 20px;
    margin: 0;
    line-height: 27px !important;
    padding: 0;
    padding-bottom: 12px;
  }
  footer .footer h4 {
    font-size: 22px;
    /* margin: 0; */
    line-height: unset;
  }

  footer .footer_sec_3 {
    width: 15%;
    justify-content: flex-end;
    padding: 0;
  }

  footer .footer_sec_3 img {
    width: 80px;
  }

  footer ul {
    padding: 0px;
    margin: 0;
  }
  footer ul li {
    padding: 0px;
    margin: 0;
  }
  footer .footer .text {
    font-size: 17px;
  }
}

@media screen and (max-width: 1024px) {
  footer .container {
    padding: 0;
  }
  footer .container .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  footer .footer_sec_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  footer .footer .text {
    margin-left: 0;
  }
  footer .footer_sec_2 {
    margin-bottom: 30px;
    width: 100%;
  }
  .footer_sec_2 .footer_sec_22 {
    display: flex;
    flex-direction: column;
  }

  footer li a {
    font-size: 20px;
    margin: 0;
    line-height: 27px !important;
    padding: 0;
    padding-bottom: 20px;
  }
  footer .footer h4 {
    font-size: 22px;
    line-height: unset;
    margin-bottom: 20px;
  }
  footer .footer_sec_3 {
    width: 15%;
    justify-content: flex-end;
    position: absolute;
    right: 60px;
    bottom: 80px;
  }

  footer .footer_sec_3 img {
    width: 70px;
  }

  footer ul {
    padding: 0px;
    margin: 0;
  }
  footer ul li {
    padding: 0px;
    margin: 0;
  }
  footer .footer .text {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  footer {
    padding-top: 36px;
    padding-bottom: 40px;
  }
  footer .container {
    padding: 0;
  }
  footer .footer {
    padding: 0 !important;
  }

  footer .footer_sec_1 {
    text-align: center;
    margin-bottom: 10px;
  }
  footer .footer_sec_1 > a > img {
    width: 260px;
  }
  footer .footer .text {
    margin-top: 0;
  }
  footer .container .footer .footer_sec_2 {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 0;
  }
  footer .container .footer .footer_sec_2 .footer_sec_22 {
    /* align-items: center; */
    width: 100%;
    margin-bottom: 32px;
    align-items: center;
  }

  footer .container .footer h4 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 16px;
  }
  footer .container .footer ul li a {
    font-size: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 0;
  }
  footer .container .footer .footer_sec_3 {
    position: static;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 0;
  }
  footer .footer .text {
    font-size: 15px;
    text-align: center;
  }
  .footer_bottom p{
    font-size: 16px;
    line-height: 26px;
    margin: 8px 0px;
  }
}
