@import "../../constants/colors.css";

.form_code_sec {
  background-color: var(--page-background-color-primary);
  /* padding: 60px 0px 100px 0px; */
}

/* .form_code_sec h1 {
    margin-bottom: 70px;
    font-weight: 600;
    font-size: 36px;
    line-height: 29px;
    color: var(--text-color);
    text-align: center;
} */

.form_code_sec .form_code {
  border-radius: 10px 10px 0px 0px;
  max-width: 630px;
  margin: 0px auto;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

.form_code_sec .content_body {
  padding: 10px 15px;
}

.form_code_sec .content_body > img {
  width: 100%;
}

.form_code_sec h3 {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

.form_code_sec .link_div {
  display: flex;
  align-items: center;
}
.form_code_sec .link_div > p {
  padding: 0;
  margin: 0;
  margin-left: 5px;
}

.form_code_sec .link_div > a {
  color: var(--text-color);
  text-decoration: underline;
}

.tooltip {
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
