.loader-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 999999;
    backdrop-filter: blur(5px);
    top: 0;
    /* cursor:; */
    scroll-behavior: none;
  }
  
  .loader-dot1 {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: var(--linear-primary);
    animation: loaderAnimation 1.5s infinite ease-in-out;
  }
  
  @keyframes loaderAnimation {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  