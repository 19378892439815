@import "../constants/colors.css";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 700px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  border-radius: 12px;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  padding: 10px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-footer button {
  font-weight: 600;
  font-size: 28px;
  color: var(--white);
  padding: 15px 60px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 60px;
}

.modal-body .items .item {
  display: flex;
  justify-content: space-between;
}

.modal-body .items .item p {
  font-size: 21px;
  font-weight: 400;
  line-height: 29px;
  margin: 0;
  padding-bottom: 45px;
  color: var(--text-color);
}

.modal-body .items .item:last-child p {
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  color: var(--text-color);
  padding-bottom: 30px;
}

/* Modal Media Queries */

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .modal.enter-done .modal-content {
    margin: 250px;
  }
  .modal-body {
    padding: 40px;
  }
  .modal-body .items .item p {
    font-size: 19px;
    padding-bottom: 20px;
  }
  .modal-body .items .item:last-child p {
    padding-bottom: 20px;
  }
  .modal-footer button {
    font-size: 24px;
    padding: 15px 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modal.enter-done .modal-content {
    margin: 200px;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-body .items .item p {
    font-size: 17px;
    padding-bottom: 15px;
  }
  .modal-body .items .item:last-child p {
    padding-bottom: 15px;
  }
  .modal-footer button {
    font-size: 22px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  .modal.enter-done .modal-content {
    margin: 50px;
  }
  .modal-body {
    padding: 15px;
  }
  .modal-body .items .item p {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .modal-body .items .item:last-child p {
    padding-bottom: 10px;
  }
  .modal-footer button {
    font-size: 16px;
    padding: 8px 24px;
  }
}
