@import "../constants/colors.css";

.container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

.container textarea {
  display: block;
  width: 100%;
  resize: none;
  height: 218px;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

.container input:focus {
  color: var(--input-focus-color);
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus);
  outline: 0;
}
