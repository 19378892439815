@import "../constants/colors.css";

.radio_div {
  align-items: center;
  display: flex;
  margin: 0;
}

.with_padding_bottom {
  margin-bottom: 22px;
}
.radio_div .rad_div {
  align-self: flex-start;
}

.radio_div .rad_div input[type="radio"] {
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid var(--text-color);
  transition: 0.2s all linear;
  margin-right: 5px;
  position: relative;
}

.radio_div > label {
  color: var(--text-color);
  margin-left: 5px;
  margin-top: -3px;
  font-size: 21px;
  font-weight: 400;
}

.radio_main .radio_div input:checked {
  border: 10px solid var(--text-color);
  outline: unset !important;
  background-color: var(--text-color);

}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .radio_div > label {
    font-size: 19px !important;
  }

  .radio_div .rad_div input[type="radio"] {
    width: 19px !important;
    height: 19px !important;
  }
  .radio_main .radio_div input:checked {
    border: 9.8px solid var(--text-color);
    outline: unset !important;
  }
  .with_padding_bottom {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .radio_div > label {
    font-size: 17px !important;
  }

  .radio_div .rad_div input[type="radio"] {
    width: 19px !important;
    height: 19px !important;
  }
  .radio_main .radio_div input:checked {
    border: 9.8px solid var(--text-color);
    outline: unset !important;
    background-color: var(--text-color);
  }
  .with_padding_bottom {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .radio_div > label {
    font-size: 14px !important;
  }

  .radio_div .rad_div input[type="radio"] {
    width: 17px !important;
    height: 17px !important;
  }
  .radio_main .radio_div input:checked {
    border: 8.8px solid var(--text-color);
    outline: unset !important;
  }
  .with_padding_bottom {
    margin-bottom: 12px !important;
  }
}
