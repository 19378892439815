:root {
  --black: #000;
  --white: #fff;
  --message: #00a0d2;
  --error: #f44336;
  --success: #4caf50;
  --info-toast:#e56f2f;
  --success-toast: #34656a;
  --error-toast: #D81A1A;
  --disabled: #c6c5ca;
  --text-color: #34656a;
  --linear-primary: #e46e2e;
  --linear-secondary: #f8aa58;
  --box-header-linear-primary: #e56f2f;
  --box-header-linear-secondary: #f9ab59;
  --orange-button-background: #ee853f;
  --orange-decrement-background: #e67130;
  --ul-background: #444444;
  --active-nav: #ffffff40;
  --input-bg: #f1f1f1;
  --box-shadow-focus: #c1c1c1;
  --box-shadow-focus-2: #cccccc;
  --box-shadow-1: #0000001a;
  --input-focus-color: #111;
  --carousel-bg: #4e5496;
  --googleBtn-color: #444;
  --or-divider: #4444444d;
  --or-text: #44444480;
  --primary-authBtn: #95a5a6;
  --boxshadow-primary-authBtn: #00000033;
  --ul-li-anchor: #324798;
  --page-background-color-primary: #e5e5e5;
  --line-divider: #c2cecf;
  --form-code-background: #e2e2e2;
  --billing-body-content: #d9d9d9;
  --pagination-li-background: #34656a40;
  --signup-input-color: #666;
  --login-btn-hover-color: #758081;
}
