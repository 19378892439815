@import "../../constants/colors.css";

#get_support_main {
  background: var(--page-background-color-primary);
  /* padding: 64px 78px 124px 77px; */
}

#get_support_main .get_support {
  margin-top: 74px;
}

#get_support_main .get_support_body {
  padding: 33px 65px 60px 77px;
  background-color: var(--white);
  border-radius: 0px 0px 10px 10px;
}

#get_support_main .get_support_body .content_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

#get_support_main .get_support_body .content_box > .input_container {
  width: 50%;
}

#get_support_main .get_support_body .content_box .container {
  margin: 0;
  padding: 0;
}

#get_support_main .get_support_body .textarea_div .container {
  width: 95.5%;
}

#get_support_main .next_buttn {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#get_support_main .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

/* getSupport Media Queries */

@media screen and (max-width: 1200px) {
  #get_support_main .get_support .get_support_body {
    padding: 32px;
  }
  .billing_history_sec .billing_history .main_body .body {
    padding: 50px 0;
  }
  #get_support_main
    .get_support
    .get_support_body
    .content_box
    > .container
    label {
    margin-top: 30px !important;
    font-size: 19px;
    margin-bottom: 9px;
  }
  #get_support_main .next_buttn button {
    font-size: 19px;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  #get_support_main .get_support .get_support_body {
    padding: 32px 20px;
  }

  #get_support_main
    .get_support
    .get_support_body
    .content_box
    > .container
    label {
    margin-top: 20px !important;
    font-size: 17px;
    margin-bottom: 7px;
  }
  #get_support_main .next_buttn button {
    font-size: 17px;
    padding: 10px 45px;
  }
}

@media screen and (max-width: 767px) {
  #get_support_main .get_support {
    margin-top: 15px;
  }
  #get_support_main .get_support .get_support_body {
    padding: 32px 20px;
  }

  #get_support_main .get_support_body .content_box > .input_container {
    width: 100%;
  }

  #get_support_main .get_support .get_support_body .content_box .chat_img {
    display: none;
  }
  #get_support_main .get_support .get_support_body .content_box > .container {
    width: 100% !important;
    padding: 0 !important;
  }

  #get_support_main
    .get_support
    .get_support_body
    .content_box
    > .container
    label {
    margin-top: 15px !important;
    font-size: 15px;
    margin-bottom: 5px;
  }
  #get_support_main
    .get_support
    .get_support_body
    .content_box
    > .container
    textarea {
    height: 80px;
  }

  #get_support_main .next_buttn button {
    font-size: 17px;
    padding: 5px 30px;
  }
}
