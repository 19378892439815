.page_title_header {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  /* margin: 40px 0px 0px 60px; */
}

.page_title_header h1 {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  margin: 0;
}

.page_title_header .side_buttons_div {
  position: absolute;
  right: 0;
  top: -40px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.page_title_header .side_buttons_div p {
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  color: var(--text-color);
  margin: 0;
}

.page_title_header .side_buttons_div button {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  background-color: var(--text-color);
  color: var(--white);
  padding: 10px 50px;
  margin-right: 0px;
  border-radius: 30px;
}

@media screen and (max-width: 1200px) {
  .page_title_header .side_buttons_div button {
    font-size: 18px;
    padding: 10px 40px;
  }
  .page_title_header .side_buttons_div {
    gap: 15px;
  }
  .page_title_header .side_buttons_div p {
    font-size: 21px;
  }
}

@media screen and (max-width: 767px) {
  .page_title_header {
    justify-content: space-between;
    align-items: baseline;
  }
  .page_title_header .side_buttons_div {
    position: inherit;
    top: 0;
  }
  .page_title_header .side_buttons_div p {
    display: none;
  }
}
