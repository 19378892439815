@import "../../constants/colors.css";

.react_pagination {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0px auto;
  cursor: pointer;
  text-align: center;
}

.react_pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--box-header-linear-primary);
  color: var(--box-header-linear-primary);
}

.react_pagination :not(.pagination__link--disabled) a:hover {
  color: var(--white);
  background: var(--box-header-linear-primary);
}

.react_pagination ul {
  margin: 0;
  padding: 0;
}
.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: var(--white);
  background: var(--box-header-linear-primary);
}

.pagination__link--disabled a {
  color: var(--disabled);
  border: 1px solid var(--box-header-linear-primary);
}

/* /////////////////////////// Media Quarry start  //////////////////// */

@media screen and (max-width: 1200px) {
  .react_pagination {
    /* width: 50%; */
  }
}

@media screen and (max-width: 991px) {
  .react_pagination {
    /* width: 60%; */
  }
}

@media screen and (max-width: 767px) {
  .react_pagination {
    /* width: 95%; */
  }
  .pagination__link--disabled a,
  .react_pagination a {
    font-size: 12px;
    padding: 5px 10px;
  }
  .react_pagination {
    height: 70px;
  }
}
