@import "../../constants/colors.css";

#confirm_selection_main {
  /* padding: 64px 77px 75px 77px; */
  background-color: var(--page-background-color-primary);
}

#confirm_selection_main .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#confirm_selection_main .box_head {
  padding-bottom: 74px;
}

/* 
#confirm_selection_main .box_head > h1 {
  font-weight: 600;
  font-size: 36px;
  color: var(--text-color);
  text-align: center;
  margin: 0;
  padding: 0;
} */

#confirm_selection_main .confirm_selection {
  display: flex;
  justify-content: space-between;
}

#confirm_selection_main .confirm_selection .included_items,
.extras {
  width: calc(50% - 21px);
}

#confirm_selection_main .confirm_selection .included_items {
  margin-right: 21px;
}

#confirm_selection_main .confirm_selection .included_items .included_items_body,
#confirm_selection_main .confirm_selection .extras .extras_body {
  padding: 40px 54px 45px 44px;
  background-color: var(--white);
  box-shadow: 0px 3.8px 6.65px rgb(0 0 0 / 10%);
  border-radius: 0 0px 10px 10px;
}

#confirm_selection_main .confirm_selection .extras .extras_body {
  padding: 31px 53px 38px 44px;
}

#confirm_selection_main
  .confirm_selection
  .included_items
  .included_items_body
  .disc_container
  .container {
  margin: 0;
  padding: 0;
}

#confirm_selection_main
  .confirm_selection
  .included_items
  .included_items_body
  .disc_container
  .container
  label {
  margin: 0;
  padding: 0;
  margin-bottom: 11px;
}

#confirm_selection_main .confirm_selection .extras {
  margin-left: 21px;
}

#confirm_selection_main .divider {
  border-bottom: 1px solid var(--line-divider);
  margin-top: 11px;
  margin-bottom: 16px;
}

#confirm_selection_main .double_item_box {
  display: flex;
  justify-content: space-between;
}

#confirm_selection_main .extras .item_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

#confirm_selection_main .extras .item_box .total_pay_value {
}

#confirm_selection_main .extras .item_box h4 {
  font-weight: 700;
}

#confirm_selection_main .extras .extras_body .item_box > h4,
.itemx {
  margin: 0;
  color: var(--text-color);
  padding: 0;
  font-size: 21px;
  line-height: 29px;
}

#confirm_selection_main .extras .extras_body .total_pay_value {
  display: flex;
  justify-content: space-between;
}

#confirm_selection_main .extras .extras_body .total_pay_value > #total_payment,
#confirm_selection_main
  .extras
  .extras_body
  .total_pay_value
  > #total_payment_value {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

#confirm_selection_main .double_item_box .item:first-child {
  width: 70%;
}

#confirm_selection_main .double_item_box .item:last-child {
  width: 30%;
}

#confirm_selection_main .single_item_box h4,
#confirm_selection_main .single_item_box p,
.double_item_box .item h4,
.double_item_box .item p {
  color: var(--text-color);
  margin: 0;
  padding: 0;
  margin-bottom: 13px;
  font-size: 21px;
}

#confirm_selection_main .extras_body #extra_p_text {
  margin: 0;
  margin-bottom: 32px;
  font-size: 21px;
  width: 95%;
  text-align: justify;
  line-height: 29px;
}

#confirm_selection_main .button {
  margin-top: 80px;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
}

#confirm_selection_main .button button {
  font-size: 21px;
  width: 638px;
  line-height: 29px;
  font-weight: 600;
  color: var(--white);
  padding: 30px 0;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#confirm_selection_main .thank_you_url {
  margin-top: 30px;
}

#confirm_selection_main .extras .extras_body .item_box #vip .radio_div {
  display: block;
}

#confirm_selection_main .extras .extras_body .item_box .radio_div {
  justify-content: space-between;
}

/* confirmSelection Media Queries */

@media screen and (max-width: 1200px) {
  #confirm_selection_main
    .box
    > .box_body
    > .confirm_selection
    > .included_items
    > .included_items_body,
  .extras > .extras_body {
    padding: 20px !important;
  }

  #confirm_selection_main .confirm_selection .extras .extras_body {
    padding: 20px !important;
  }
  #confirm_selection_main .single_item_box h4,
  .double_item_box .item h4 {
    font-size: 19px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .double_item_box .item:first-child {
    width: 60% !important;
  }

  #confirm_selection_main .double_item_box .item:last-child {
    width: 40% !important;
  }

  #confirm_selection_main .single_item_box p,
  .double_item_box .item p {
    font-size: 19px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .divider {
    margin-top: 5.5px;
    margin-bottom: 8px;
  }

  #confirm_selection_main .extras .item_box h4 {
    font-size: 19px !important;
  }
  #confirm_selection_main .extras_body #extra_p_text {
    font-size: 19px;
    line-height: 20px !important;
    margin-bottom: 20px;
  }
  #confirm_selection_main .extras .extras_body .item_box > h4 {
    line-height: 20px;
    font-size: 19px !important;
  }
  #confirm_selection_main .item_box .with_padding_bottom {
    margin-bottom: 5px !important;
  }
  #confirm_selection_main .item_box .radio_main .radio_div label {
    font-size: 17px !important;
  }
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment,
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment_value {
    font-size: 24px !important;
    line-height: 20px;
  }

  #confirm_selection_main .button {
    margin: 0 !important;
    padding-top: 40px !important;
  }

  #confirm_selection_main .button .create_my_form_btn button {
    width: unset !important;
    padding: 15px 60px;
  }
}

@media screen and (max-width: 1024px) {
  #confirm_selection_main
    .box
    > .box_body
    > .confirm_selection
    > .included_items
    > .included_items_body,
  .extras > .extras_body {
    padding: 20px !important;
  }

  #confirm_selection_main .confirm_selection .extras .extras_body {
    padding: 20px !important;
  }

  #confirm_selection_main .single_item_box h4,
  .double_item_box .item h4 {
    font-size: 19px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .double_item_box .item:first-child {
    width: 60% !important;
  }

  #confirm_selection_main .double_item_box .item:last-child {
    width: 40% !important;
  }

  #confirm_selection_main .single_item_box p,
  .double_item_box .item p {
    font-size: 17px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .divider {
    margin-top: 5.5px;
    margin-bottom: 8px;
  }

  #confirm_selection_main .extras .item_box h4 {
    font-size: 19px !important;
  }
  #confirm_selection_main .extras_body #extra_p_text {
    font-size: 17px;
    line-height: 20px !important;
    margin-bottom: 20px;
  }
  #confirm_selection_main .extras .extras_body .item_box > h4 {
    line-height: 20px;
    font-size: 17px;
  }
  #confirm_selection_main .item_box .with_padding_bottom {
    margin-bottom: 5px !important;
  }
  #confirm_selection_main .item_box .radio_main .radio_div label {
    font-size: 17px !important;
  }
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment,
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment_value {
    font-size: 22px !important;
    line-height: 20px;
  }

  #confirm_selection_main .button {
    margin: 0 !important;
    padding-top: 30px !important;
  }

  #confirm_selection_main .button .create_my_form_btn button {
    width: unset !important;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 767px) {
  #confirm_selection_main .box > .box_body > .confirm_selection {
    flex-direction: column;
  }

  #confirm_selection_main
    .box
    > .box_body
    > .confirm_selection
    > .included_items,
  .extras {
    width: 100%;
    margin: 0 !important;
  }

  #confirm_selection_main
    .box
    > .box_body
    > .confirm_selection
    > .included_items
    > .included_items_body,
  .extras > .extras_body {
    padding: 15px !important;
  }
  #confirm_selection_main .confirm_selection .extras .extras_body {
    padding: 15px !important;
  }

  #confirm_selection_main .box > .box_body > .confirm_selection > .extras {
    margin-top: 20px !important;
  }

  #confirm_selection_main .single_item_box h4,
  .double_item_box .item h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .double_item_box .item:first-child {
    width: 60% !important;
  }

  #confirm_selection_main .double_item_box .item:last-child {
    width: 40% !important;
  }

  #confirm_selection_main .single_item_box p,
  .double_item_box .item p {
    font-size: 14px;
    margin-bottom: 6px;
  }

  #confirm_selection_main .divider {
    margin-top: 5.5px;
    margin-bottom: 8px;
  }

  #confirm_selection_main .extras .item_box h4 {
    font-size: 16px !important;
  }
  #confirm_selection_main .extras_body #extra_p_text {
    font-size: 14px;
    line-height: 20px !important;
    margin-bottom: 20px;
  }
  #confirm_selection_main .extras .extras_body .item_box > h4 {
    line-height: 20px;
  }
  #confirm_selection_main .item_box .with_padding_bottom {
    margin-bottom: 5px !important;
  }
  #confirm_selection_main .item_box .radio_main .radio_div label {
    font-size: 13px !important;
  }
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment,
  #confirm_selection_main
    .extras
    .extras_body
    .total_pay_value
    > #total_payment_value {
    font-size: 21px !important;
    line-height: 20px;
  }

  #confirm_selection_main .button {
    margin: 0 !important;
    padding-top: 20px !important;
  }

  #confirm_selection_main .button .create_my_form_btn button {
    width: unset !important;
    padding: 10px 40px;
  }
  #confirm_selection_main .thank_you_url {
    margin-top: 20px;
  }
}
