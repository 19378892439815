@import "../../../constants/colors.css";

#myacoount_sec {
  /* padding: 64px 78px 75px 77px; */
  background-color: var(--page-background-color-primary);
}


#myacoount_sec .button_sec button {
  display: block;
}

#myacoount_sec .button_sec p:nth-child(1) {
  background-color: transparent;
  border: none;
  /* cursor: pointer; */
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  color: var(--text-color);
  margin: 0;
}

#myacoount_sec .button_sec button {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  background-color: var(--text-color);
  color: var(--white);
  padding: 10px 50px;
  margin-right: 0px;
  border-radius: 30px;
}

#myacoount_sec .button_sec a:nth-child(2) {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  background-color: var(--text-color);
  color: var(--white);
  padding: 10px 50px;
  margin-right: 0px;
  border-radius: 30px;
}

#myacoount_sec .account_boxes {
  display: flex;
  width: 100%;
  margin-top: 75px;
}

#myacoount_sec .account_boxes .box_sec1 {
  width: calc(50% - 20px);
  margin-right: 20px;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#myacoount_sec .account_boxes .box_sec2 {
  width: calc(50% - 20px);
  margin-left: 20px;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#myacoount_sec .account_boxes .box_body {
  padding: 30px 30px 60px 40px;
  text-align: left;
}

#myacoount_sec .account_boxes .box_body p {
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-top: 0px;
  margin-bottom: 30px;
}

#myacoount_sec .account_boxes .box_body button {
  background: var(--text-color);
  border-radius: 30px;
  color: var(--white);
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  display: block;
  border: none;
  cursor: pointer;
  font-family: "Lato";
}

#myacoount_sec .account_boxes .box_body button:nth-child(1) {
  padding: 10px 20px;
  margin-bottom: 45px;
}

#myacoount_sec .account_boxes .box_body button:nth-child(2) {
  padding: 10px 70px;
}

#myacoount_sec .account_boxes .box_body label {
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  padding-bottom: 8px;
  display: block;
  margin-top: 35px;
  margin-bottom: 0px;
}

#myacoount_sec .account_boxes .box_body .input_container:nth-child(1) label {
  margin-top: 0px;
}

#myacoount_sec .account_boxes .box_body input {
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  width: 100%;
  min-height: 45px;
  padding: 5px 10px;
  box-sizing: border-box;
}

#myacoount_sec .notification_sec {
  padding: 70px 0px 0px 0px;
}

#myacoount_sec .notification_sec p {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 25px;
}

#myacoount_sec .notification_sec .content {
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 60px;
  display: flex;
}

#myacoount_sec .notification_sec .content .switch_sec {
  margin-left: 20px;
}

#myacoount_sec .notification_sec .invoice {
  max-width: 700px;
  margin-left: 52%;
}

#myacoount_sec .notification_sec .invoice p span.last_payment {
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  padding-left: 70px;
}

#myacoount_sec .notification_sec .invoice button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 40px;
  background: var(--text-color);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

/* switch css */
#myacoount_sec .notification_sec .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

#myacoount_sec .notification_sec .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#myacoount_sec .notification_sec .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--box-shadow-focus-2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
  background-color: var(--box-shadow-1);
  height: 30px;
  width: 60px;
}

#myacoount_sec .notification_sec .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
}

#myacoount_sec .notification_sec input:checked + .slider {
  background-color: var(--text-color);
}

#myacoount_sec .notification_sec input:focus + .slider {
  box-shadow: 0 0 1px var(--text-color);
}

#myacoount_sec .notification_sec input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

#myacoount_sec .notification_sec .slider.round {
  border-radius: 34px;
}

#myacoount_sec .notification_sec .slider.round:before {
  border-radius: 50%;
}

/* //////////////////////////////////////////Billing history page css/////////////////////////////////////////////////////// */

.billing_history_sec {
  background-color: var(--page-background-color-primary);
  /* padding: 64px 78px 124px 77px; */
}

.billing_history_sec .billing_history {
  max-width: 1285px;
  margin: 0px auto;
}

.billing_history_sec .billing_history .billing_main {
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

.billing_history_sec .billing_history h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  margin-bottom: 74px;
}

.billing_history_sec .main_body {
  padding: 40px;
}

.billing_history_sec .billing_history .main_body .body_header ul {
  display: flex;
}

.billing_history_sec .billing_history .main_body .body_header ul li {
  list-style-type: none;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-right: 100px;
}

.billing_history_sec .billing_history .main_body .body {
  padding: 70px 0px;
}

.billing_history_sec .billing_history .main_body .body .content {
  max-width: 900px;
  background: var(--billing-body-content);
  height: 250px;
  margin: 0px auto;
}

.inactive_button {
  background-color: var(--signup-input-color) !important;
  cursor: not-allowed !important;
}

/* billingHistory Media Queries */

@media screen and (max-width: 1200px) {
  /* .billing_history_sec {
    padding: 52px 64px 100px 64px;
  } */

  .billing_history_sec .main_body {
    padding: 32px;
  }

  .billing_history_sec .billing_history .main_body .body {
    padding: 50px 0;
  }

  .billing_history_sec .billing_history .main_body .body_header ul {
    margin: 0;
    padding: 0;
  }

  .billing_history_sec .billing_history .main_body .body_header ul li {
    font-size: 18px;
  }

  /* myaccount css */


  #myacoount_sec .button_sec p:nth-child(1) {
    font-size: 21px;
  }

  #myacoount_sec .button_sec button {
    font-size: 18px;
    padding: 10px 40px;
  }

  #myacoount_sec .button_sec a:nth-child(2) {
    font-size: 18px;
    padding: 10px 40px;
  }

  #myacoount_sec .account_boxes {
    margin-top: 15px;
  }
}

@media screen and (max-width: 1024px) {
  /* .billing_history_sec {
    padding: 42px 56px 70px 56px;
  } */
  .billing_history_sec .billing_history .main_body {
    padding: 32px 20px;
  }

  .billing_history_sec .billing_history .main_body .body {
    padding: 35px 0;
  }

  .billing_history_sec .billing_history .main_body .body_header ul li {
    margin-right: 70px;
    font-size: 17px;
  }

  #myacoount_sec .notification_sec .invoice {
    margin: 0px auto;
    text-align: center;
  }

  /* myaccount css */

  #myacoount_sec .button_sec p:nth-child(1) {
    font-size: 19px;
    line-height: normal;
  }

  #myacoount_sec .button_sec button {
    font-size: 17px;
    padding: 8px 30px;
  }

  #myacoount_sec .button_sec a:nth-child(2) {
    font-size: 17px;
    padding: 8px 30px;
  }
}

@media screen and (max-width: 767px) {
  /* .billing_history_sec {
    padding:30px 15px;
  } */

  .billing_history_sec .billing_history .main_body {
    padding: 32px 20px;
  }

  .billing_history_sec .billing_history .main_body .body {
    padding: 20px 0;
  }

  .billing_history_sec .billing_history .main_body .body_header ul li {
    margin-right: auto;
    font-size: 15px;
  }

  /* myaccount css */
  #myacoount_sec .button_sec p:nth-child(1) {
    display: none;
  }

  #myacoount_sec .button_sec button {
    font-size: 15px;
    padding: 5px 20px;
  }

  #myacoount_sec .button_sec a:nth-child(2) {
    font-size: 15px;
    padding: 5px 20px;
  }


  #myacoount_sec .account_boxes {
    display: block !important;
  }

  #myacoount_sec .account_boxes .box_sec1 {
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
  }

  #myacoount_sec .account_boxes .box_sec2 {
    margin: 0px;
    width: 100%;
  }

  #myacoount_sec .account_boxes .box_body {
    padding: 15px;
  }

  #myacoount_sec .account_boxes .box_sec2 .box_body {
    padding-bottom: 25px;
  }

  #myacoount_sec .account_boxes .box_body p {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 20px;
  }

  #myacoount_sec .account_boxes .box_body button {
    font-size: 15px;
    text-align: center;
    margin: 10px auto;
  }

  #myacoount_sec .account_boxes .box_body button:nth-child(1) {
    margin-bottom: 20px;
  }

  #myacoount_sec .account_boxes .box_body button:nth-child(2) {
    padding: 10px 55px;
  }

  #myacoount_sec .notification_sec {
    padding: 30px 0px 0px 0px;
  }

  #myacoount_sec .notification_sec p {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    text-align: left;
  }

  #myacoount_sec .notification_sec .content {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  #myacoount_sec .notification_sec .slider {
    height: 25px;
    width: 55px;
  }

  #myacoount_sec .notification_sec .slider:before {
    height: 21px;
    width: 21px;
  }
  #myacoount_sec .notification_sec .invoice button {
    font-size: 15px;
    line-height: 24px;
  }
  #myacoount_sec .notification_sec .invoice p span.last_payment {
    font-size: 18px;
    line-height: normal;
    padding-left: 15px;
  }
}
