@import "../../constants/colors.css";

#confirmation_modal_content{
  margin-top: 20px;
}
#confirmation_modal_content .main_container {
  display: flex;
  justify-content: center;
}

#confirmation_modal_content .inner_container {
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
  /* width: 80%; */
}

#confirmation_modal_content .inner_container .yes_button {
  padding: 0px;
  width: 120px;
  height: 40px;
  font-size: 17px;
  background: var(--box-header-linear-primary);
  font-weight: 600;
  /* font-size: 21px; */
  line-height: 29px;
  border: none;
  border-radius: 30px;
  color: var(--white);
  cursor: pointer;
}

#confirmation_modal_content .inner_container .no_button {
  padding: 0px;
  width: 120px;
  height: 40px;
  font-size: 17px;
  background: var(--ul-background);
  font-weight: 600;
  /* font-size: 21px; */
  line-height: 29px;
  border: none;
  border-radius: 30px;
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s;
}
#confirmation_modal_content h2{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
#confirmation_modal_content .yes_button:hover{
  background-color: transparent;
  color: var(--box-header-linear-primary);
  border: 2px solid var(--box-header-linear-primary);
}

@media screen and (max-width: 767px) {
  #confirmation_modal_content h2 {
    font-size: 20px;
  }
  #confirmation_modal_content .inner_container .no_button,
  #confirmation_modal_content .inner_container .yes_button {
    font-size: 15x;
    width: 70px;
    height: 30px;
  }
}
