.column_form_main small {
  color: red;
  font-size: 11px;
}
.column_form_main h1,
.column_form_main h4,
.column_form_main h6,
.column_form_main p {
  margin: 0px;
}

.column_form_main .logo_image_div {
  /* font-size: 20px; */
  color: #000;
  padding: 20px 0px;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.column_form_main .logo_image_div > .logo_image {
  /* width: 95px; */
  height: 95px;
  object-fit: contain;
}

.column_form_main .single_column_main {
  width: 70%;
}

.column_form_main .double_column_main {
  width: 50%;
  height: 100%;
  /* border-left: 1px solid #ccc; */
}

.one .double_column_main {
  display: none;
}
.one .single_column_main {
  width: 100%;
}
.column_form_main .container_column {
  max-width: 1600px;
  padding: 30px 30px;
  margin: 0px auto;
  display: flex;
  gap: 20px;
  padding-top: 0px;
}

.column_form_main .Quantity_sec {
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
}
.column_form_main .Quantity_sec input {
  max-width: 120px;
}

.column_form_main input,
.column_form_main select {
    padding: 10px;
    margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.column_form_main .carddetails input {
  margin-bottom: 15px;
  margin-top: inherit !important;
}

/* .column_form_main #multiple_product {
  padding: 10px 5px;
} */

.column_form_main .total_price_info {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.column_form_main .total_price_info p {
  /* width: 50%; */
  /* color: #006600; */
  margin-top: 10px;
  font-size: 15px;
}

.column_form_main .total_amount p {
  font-weight: 600;
}
.column_form_main .discount_div_info p {
  /* width: 50%; */
  /* color: red; */
  /* margin-top: 10px; */
  /* font-size: 15px; */
}
.column_form_main .total_amount p {
  font-weight: 600;
}

.column_form_main .product__name {
  color: #006600;
}

.column_form_main .product_select {
  margin-bottom: 10px;
  width: 35%;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.block {
  display: block !important;
}

.column_form_main .euro_price {
  margin: 25px 0;
}

.column_form_main .euro_price span {
  color: #a50000;
  margin-left: 10px;
}

.column_form_main .double_col {
  display: flex;
  gap: 15px;
}

.column_form_main .double_col input,
.column_form_main .double_col select {
  width: 50%;
}

.column_form_main .single_thirty_col input {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.column_form_main .single_col input {
  width: 100%;
}

.column_form_main .single_fifty_col {
  display: flex;
  gap: 15px;
}

.column_form_main .single_fifty_col input {
  width: 50%;
}

.column_form_main .image_sec img {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  /* / margin: 0px auto; / */
}

.column_form_main .double_column_main .content_sec {
  background-color: #f8f7fa;
  padding: 20px;
}

.column_form_main .product_price_sec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding: 15px 0px;
  font-size: 15px;
}

.no_pad {
  padding: 0 !important;
}

.column_form_main .image_sec {
  display: flex;
}

.column_form_main .double_column_main .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid;
  padding-bottom: 0px;
  margin-bottom: 12px;
  font-size: 15px;
}
.column_form_main .total_amount p {
  font-weight: 600;
}

.column_form_main .double_column_main .discount_input {
  padding: 15px 0;
}
.column_form_main .double_column_main .discount_input_inner {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid;
  /* padding-bottom: 12px; */
  /* margin-bottom: 12px; */
  font-size: 13px;
}

.column_form_main .double_column_main .discount_input_inner input {
  margin: 0;
  width: 80%;
}

.column_form_main
  .double_column_main
  .discount_input_inner
  .discount_apply_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 16px;
  cursor: auto;
  color: var(--white);
  background-color: var(--disabled);
  margin-left: 5px;
}
.column_form_main .discount_button_active {
  background-color: var(--linear-primary) !important;
  cursor: pointer !important;
}

.column_form_main .discount_button_applied {
  background-color: var(--text-color) !important;
  cursor: auto !important;
}

.column_form_main .content_sec h6 {
  font-size: 15px;
}

.column_form_main .card_sec_input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.column_form_main .card_sec_input input {
  margin-bottom: 0px;
}
.column_form_main .card_sec_input small {
  margin-top: 5px;
}
.column_form_main .quantity_input input {
  margin-bottom: 15px;
  margin-top: 0px;
}

.column_form_main .carddetails .payment_details_form_div {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
}

.column_form_main .carddetails .payment_details_form_div .card_sec_input {
  width: calc(50% - 7.5px);
}

.column_form_main .carddetails .payment_details_form_div .card_sec_input label {
  /* margin-bottom: 10px; */
}

.column_form_main .submit_btn {
  background-color: #e1692a;
  padding: 10px 35px;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.mobile_show {
  display: none;
}
.details_img_sec {
  display: flex;
  gap: 20px;
}
.details_img_sec .flex_section_img,
.details_img_sec .image_main_div {
  width: calc(50% - 10px);
  overflow: hidden;
}
.details_img_sec .flex_section_img {
  display: flex;
  width: 100%;
  background: #f8f7fa;
  justify-content: space-between;
  padding: 20px;
}

.column_form_main .error_input {
  width: 50%;
}
.column_form_main .error_input input,
.column_form_main .error_input select {
  width: 100%;
}

/* #multiple_product {
  margin-top: 10px;
} */

.no_bg {
  background: none !important;
  padding: 0 !important;
}

.main_multiple_single_col {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bold_primary {
  font-weight: 600 !important;
  color: var(--text-color) !important;
}

.carddetails label {
  /* margin-bottom: 10px !important; */
  display: block;
}

.carddetails input,
.carddetails .StripeElement {
  border: 1px solid #585858;
  display: block;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}


.carddetails input::placeholder {
  color: #aab7c4;
}

.column_form_main .carddetails input:focus,
.carddetails .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.carddetails .StripeElement.IdealBankElement,
.carddetails .StripeElement.FpxBankElement,
.carddetails .StripeElement.PaymentRequestButton {
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .column_form_main input,
  .column_form_main select {
    padding: 8px 10px;
  }

  .column_form_main .container_column {
    gap: 15px;
    padding: 20px;
  }
  .column_form_main.one .container_column .single_column_main {
    width: 100%;
  }

  .column_form_main.one .double_column_main {
    width: auto;
  }

  .column_form_main .single_column_main {
    width: 65%;
  }

  .column_form_main .double_column_main {
    width: 35%;
  }
}

@media screen and (max-width: 991px) {
  .column_form_main .double_column_main .quantity_input {
    flex-direction: column;
    gap: 10px;
  }

  .column_form_main .total_price_info {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .column_form_main .error_input {
    width: 100%;
  }
  .column_form_main .container_column {
    flex-direction: column;
  }

  .column_form_main .single_column_main,
  .column_form_main .double_column_main {
    width: 100%;
    margin-top: 20px;
  }

  .mobile_show {
    display: block;
    max-width: 150px;
  }

  .mobile_hide {
    display: none;
  }

  .column_form_main h1 {
    margin-bottom: 15px !important;
    padding: 20px 0px;
    font-size: 20px !important;
  }

  .column_form_main .container_column h3 {
    font-size: 17px;
  }

  .column_form_main .double_col input,
  .column_form_main .double_col select {
    width: 100%;
  }

  .column_form_main .double_col {
    flex-direction: column;
    gap: 0px;
  }

  .column_form_main .single_thirty_col input {
    width: 100%;
  }

  .column_form_main .carddetails .payment_details_form_div .card_sec_input {
    width: 100%;
  }

  .column_form_main .submit_btn {
    margin: 0px auto;
  }
  .details_img_sec {
    flex-direction: column;
  }
  .details_img_sec .flex_section_img {
    order: 2;
    width: calc(100% - 40px);
    display: block;
  }
  .details_img_sec .image_main_div {
    order: 1;
    width: 100%;
  }
  .flex {
    display: block;
  }
  .column_form_main .double_column_main .content_sec {
    padding: 20px;
  }
  /* .details_img_sec .flex_section_img{
    padding: 0;
  } */
  .main_multiple_single_col {
    display: block;
  }
  .column_form_main .product_select {
    margin-bottom: unset;
    width: auto;
  }
  .column_form_main .euro_price {
    margin: 10px 0;
  }
}
