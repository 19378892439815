@import "../../constants/colors.css";

#Account_sec .container_sec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#Account_sec .login {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  scroll-behavior: smooth;
  overflow-y: scroll;
  -ms-overflow-style: none;
  align-items: center;
  width: 70%;
  padding: 50px;
}

#Account_sec .login::-webkit-scrollbar {
  display: none;
}

#Account_sec .inner_container_login {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

#Account_sec .inner_container_login .login_upper_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Account_sec .account_inputs {
  max-width: 600px;
  width: 100%;
  /* position: relative; */
  /* padding: 20px; */
}

#Account_sec .carousel_sendmsg_div {
  background-color: var(--carousel-bg);
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

#Account_sec .carousel_sendmsg_div .msg_btn {
  display: flex;
  justify-content: flex-end;
}

#Account_sec .carousel_sendmsg_div .msg_btn > button {
  background-color: var(--text-color);
  color: var(--white);
  border: 2px solid var(--text-color);
  padding: 10px;
  padding-right: 50px;
  border-radius: 10px 0 0 0;
  font-size: 21px;
  line-height: 29px;
  font-weight: 400;
  cursor: pointer;
}

#Account_sec .testimonial {
  padding: 40px 21px 0 20px;
}

#Account_sec .with_google_btn {
  font-size: 18px;
  line-height: 28px;
  color: var(--googleBtn-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--or-divider);
  position: relative;
}

#Account_sec .with_google_btn > p {
  text-align: center;
}

#Account_sec .with_google_btn::before {
  content: "OR";
  position: absolute;
  bottom: -7px;
  width: 45px;
  height: 20px;
  background-color: var(--white);
  left: 50%;
  transform: translateX(-50%);
  color: var(--or-text);
  opacity: 1;
  display: flex;
  justify-content: center;
}

#Account_sec .with_google_btn button {
  background: transparent;
  border: none;
  color: var(--black);
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
}

#Account_sec .input_sec {
  max-width: 500px;
  margin: 0px auto;
  margin-top: 25px;
}

#Account_sec .check_box {
  padding: 30px 0 20px 0;
  display: flex;
  align-items: center;
}

#Account_sec .check_box input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
  font-family: "Lato";
  color: var(--text-color);
}

#Account_sec .check_box > label {
  font-size: 21px;
}

#Account_sec .check_box input[type="checkbox"] {
  border: 0.1em solid var(--text-color);
  width: 20px;
  height: 20px;
  /* position: relative; */
  margin-right: 10px;
}

#Account_sec .check_box input[type="checkbox"]:checked {
  appearance: none;
  background-color: var(--text-color);
  border-radius: 2px;
  /* transform: scale(0); */
}

#Account_sec .check_box input[type="checkbox"]:checked:before {
  content: "";
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 0.15em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.3s;
  background-color: var(--white);
  position: absolute;
  left: 22%;
  top: 42%;
  transform: translate(-50%);
  transform: rotate(-45deg);
}

#Account_sec .check_box input[type="checkbox"]:checked::after {
  content: "";
  border-radius: 0.2em;
  display: inline-block;
  width: 0.4em;
  height: 0.15em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.3s;
  background-color: var(--white);
  position: absolute;
  left: 12%;
  top: 53%;
  transform: translate(-50%);
  transform: rotate(45deg);
}

#Account_sec .check_box input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

/*
#Account_sec .check_box input[type="checkbox"]:checked+label:before {
  background-color: var(--text-color);
  border-color: var(--text-color);
  color: var(--white);
} */

#Account_sec .checkbox_sec {
  font-size: 18px;
  text-align: left;
}

#Account_sec .checkbox_sec button {
  background: var(--primary-authBtn);
  border: none;
  border-radius: 3px;
  box-shadow: 1px 2px 4px var(--boxshadow-primary-authBtn);
  cursor: pointer;
  padding: 15px 30px;
  margin: 20px 0px;
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  transition: all 0.1s ease-in;
}
#Account_sec .checkbox_sec button:hover {
  background: var(--login-btn-hover-color);
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 10px,
    rgba(0, 0, 0, 0.12) 0px -1px 20px, rgba(0, 0, 0, 0.12) 0px 2px 2px,
    rgba(0, 0, 0, 0.17) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 3px 3px;
}

#Account_sec .checkbox_sec button a {
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
}

#Account_sec .checkbox_sec ul {
  display: flex;
  padding: 0px;
  margin-top: 5px;
}

#Account_sec .checkbox_sec ul li {
  list-style: none;
}

#Account_sec .checkbox_sec ul li a {
  font-size: 14px;
  color: var(--ul-li-anchor);
  font-weight: 600;
  text-decoration: none;
  padding-right: 10px;
  border-right: 1px solid var(--ul-li-anchor);
}

#Account_sec .checkbox_sec ul li:last-child a {
  border-right: none;
  padding-left: 10px;
}

/* .testimonial .carousel .slide .legend {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
  font-size: 20px;
}

.testimonial .carousel-root {
  width: 100%;
}

.testimonial .testimonial_item svg {
  position: absolute;
  left: 20px;
  top: 10px;
  color: var(--white);
}

.testimonial .carousel.carousel-slider {
  overflow: inherit;
}

.testimonial .carousel .testimonial_item {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
  font-size: 20px;
}

.testimonial .carousel .testimonial_item p {
  max-width: 80%;
  margin: 0px auto;
  text-align: justify;
  color: var(--white);
}

.testimonial .carousel .control-dots {
  bottom: -50px;
  z-index: 100;
  position: absolute;
}

.testimonial .carousel .control-dots li {
  background-color: var(--white);
  opacity: 0.5;
  color: var(--white);
  width: 10px;
  height: 10px;
} */

/* media quarry started */

@media screen and (max-width: 1200px) {
  #Account_sec .login {
    width: 65%;
  }

  #Account_sec .carousel_sendmsg_div {
    width: 35%;
  }

  #Account_sec .account_inputs {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  #Account_sec .login {
    width: 60%;
  }

  #Account_sec .carousel_sendmsg_div {
    width: 40%;
  }

  #Account_sec .with_google_btn > p {
    font-size: 15px;
    line-height: normal;
  }

  #Account_sec .with_google_btn button {
    font-size: 17px;
    line-height: 25px;
  }

  /* #Account_sec .check_box input[type="checkbox"]+label:before {
    width: 16px;
    height: 16px;
  }

  #Account_sec .check_box input[type="checkbox"]+label {
    font-size: 16px;
  } */

  #Account_sec .checkbox_sec button {
    padding: 12px 30px;
    margin: 15px 0px;
  }
}

@media screen and (max-width: 767px) {
  #Account_sec .login {
    width: auto;
    padding: 50px 15px;
  }

  #Account_sec .carousel_sendmsg_div {
    width: auto;
  }

  #Account_sec .account_inputs {
    margin-top: 20px;
  }

  #Account_sec .container_sec {
    display: block;
    width: auto;
  }

  #Account_sec .checkbox_sec button {
    margin-top: 0px;
    padding: 10px 25px;
  }

  #Account_sec .checkbox_sec button a {
    font-size: 16px;
  }

  #Account_sec .with_google_btn::before {
    font-size: 16px;
  }

  #Account_sec .check_box input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }

  #Account_sec .check_box input[type="checkbox"]:checked {
    appearance: none;
    background-color: var(--text-color);
    border-radius: 2px;
  }

  #Account_sec .check_box input[type="checkbox"]:checked:before {
    width: 0.8em;
    height: 0.1em;
    left: 22%;
    top: 44%;
  }

  #Account_sec .check_box input[type="checkbox"]:checked::after {
    width: 0.3em;
    height: 0.1em;
    left: 16%;
    top: 53%;
  }
}
