@import "../../../../constants/colors.css";

.strength_indicator {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: var(--input-bg);
  padding: 7px;
}

.strength_indicator > p {
  margin: 0;
}

.inner_container_login {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login_upper_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hint_login {
  margin: 10px 0;
}

.hint_login > p {
  text-align: left;
}

#first_signup .container_sec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#first_signup .login {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 70%;
  padding: 50px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  /* IE and Edge */
  scrollbar-width: none;
}

#first_signup .login::-webkit-scrollbar {
  display: none;
}

/* #first_signup .testimonial {
  width: 30%;
  background-color: var(--carousel-bg);
  padding-top: 100px;
} */

#first_signup .carousel_sendmsg_div {
  background-color: var(--carousel-bg);
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#first_signup .carousel_sendmsg_div .msg_btn {
  display: flex;
  justify-content: flex-end;
}

#first_signup .carousel_sendmsg_div .msg_btn > button {
  background-color: var(--text-color);
  color: var(--white);
  border: 2px solid var(--text-color);
  padding: 10px;
  padding-right: 50px;
  border-radius: 10px 0 0 0;
  font-size: 21px;
  line-height: 29px;
  font-weight: 400;
  cursor: pointer;
}

#first_signup .testimonial {
  padding: 100px 21px 0 20px;
}

#first_signup .with_google_btn {
  font-size: 18px;
  line-height: 28px;
  color: var(--googleBtn-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--or-divider);
  position: relative;
}

#first_signup .with_google_btn > p {
  text-align: center;
}

#first_signup .with_google_btn::before {
  content: "OR";
  position: absolute;
  bottom: -7px;
  width: 45px;
  height: 20px;
  background-color: var(--white);
  left: 50%;
  transform: translateX(-50%);
  color: var(--or-text);
  opacity: 1;
  display: flex;
  justify-content: center;
}

#first_signup .with_google_btn button {
  background: transparent;
  border: none;
  color: var(--black);
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
}

#first_signup .checkbox_sec {
  font-size: 18px;

  text-align: left;
}

#first_signup .checkbox_sec .inner {
  display: flex;
  align-items: center;
}

#first_signup .checkbox_sec button {
  background: var(--primary-authBtn);
  border: none;
  border-radius: 3px;
  box-shadow: 1px 2px 4px var(--boxshadow-primary-authBtn);
  cursor: pointer;
  padding: 15px 30px;
  margin: 20px 0px;
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  transition: all 0.1s ease-in;
}
#first_signup .checkbox_sec button:hover {
  background: var(--login-btn-hover-color);
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 10px,
    rgba(0, 0, 0, 0.12) 0px -1px 20px, rgba(0, 0, 0, 0.12) 0px 2px 2px,
    rgba(0, 0, 0, 0.17) 0px 2px 4px, rgba(0, 0, 0, 0.09) 0px 3px 3px;
}

/* #first_signup .checkbox_sec button a {

} */

#first_signup .checkbox_sec ul {
  display: flex;
  padding: 0px;
  margin-top: 5px;
}

#first_signup .checkbox_sec ul li {
  list-style: none;
}

#first_signup .checkbox_sec ul li a {
  font-size: 14px;
  color: var(--ul-li-anchor);
  font-weight: 600;
  text-decoration: none;
  padding-right: 10px;
  border-right: 1px solid var(--ul-li-anchor);
}

#first_signup .checkbox_sec ul li:last-child a {
  border-right: none;
  padding-left: 10px;
}

.testimonial .carousel .slide .legend {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
  font-size: 20px;
}

.testimonial .testimonial_item svg {
  position: absolute;
  left: 20px;
  top: 10px;
  color: var(--white);
}

.testimonial .carousel.carousel-slider {
  overflow: inherit;
}

.testimonial .carousel .testimonial_item {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
}

.testimonial .carousel .testimonial_item P {
  max-width: 80%;
  margin: 0px auto;
  margin-top: 40px;
  font-style: italic;
  font-size: 17px;
  font-weight: 300;
  /* line-height: 1.5; */
}
.testimonial .carousel .testimonial_item h3 {
  margin-bottom: 10px;
  margin-top: 30px;
}
.testimonial .carousel .testimonial_item h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0px auto;
}

.testimonial .carousel .control-dots {
  bottom: -50px;
  z-index: 100;
  position: absolute;
}

.testimonial .carousel .control-dots li {
  background-color: var(--white);
  opacity: 0.5;
  color: var(--white);
  width: 10px;
  height: 10px;
}

/*/////////////////////////////////////////////// billing sec css start//////////////////////////// */

#billing_info_sec {
  /* padding: 64px 78px 102px 77px; */
  background: var(--page-background-color-primary);
}

#billing_info_sec .info_bill {
  max-width: 1285px;
  margin: 0px auto;
}

#billing_info_sec .billing_inner {
  /* margin-bottom: 45px; */
  display: flex;
}

#billing_info_sec .billing_inner .box_body {
  background: var(--white);
  padding-bottom: 50px;
  border-radius: 0 0 10px 10px;
}

#billing_info_sec .billing_inner .box_body > .container,
.box_body > .selct_input {
  padding: 0;
  margin-bottom: 33px;
}

#billing_info_sec .billing_inner .box_body > .container:last-child {
  margin-bottom: 0;
}

.box_body > .selct_input {
  margin-bottom: 0;
}

.box_body > .selct_input > select {
  margin-top: 13px;
}

#billing_info_sec .billing_inner .box_body > .container > label {
  padding: 0;
  margin: 0;
  margin-bottom: 13px;
}

#billing_info_sec .billing_inner .box_body .selct_input > label {
  padding: 0;
  margin: 0;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
}

/* #billing_info_sec h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 74px;
  margin-top: 0px;
} */

#billing_info_sec .billing_one {
  width: calc(50% - 20px);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#billing_info_sec .billing_one:nth-child(1) {
  height: 100%;
  margin-right: 20px;
}
#billing_info_sec .billing_one .input_container:nth-child(1) label {
  margin-top: 0px;
}

#billing_info_sec .billing_one:nth-child(2) {
  height: 100%;
  margin-left: 20px;
}

#billing_info_sec .box_body {
  padding: 37px 52px 55px 48px;
}

#payment_detail .double_input .input_select input {
  font-size: 21px;
  /* color: var(--text-color); */
  font-weight: 400;
}

#payment_detail .double_input:nth-child(1) .input_container label {
  margin-top: 0px;
}

#payment_detail .box_body > .container,
.box_body .selct_input {
  padding: 0;
  margin-bottom: 33px;
  margin-bottom: 10px;
  margin-top: 30px;
}

#payment_detail .selct_input select {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  /* font-size: 16px; */
  font-weight: 600;
}

#payment_detail .box_body .selct_input > label {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;

  color: var(--text-color);
}

#billing_info_sec .register_pagination {
  padding-top: 47px;
}

#billing_info_sec .info_bill .register_pagination .checkbox_main {
  display: flex;
  justify-content: center;
}

/* .register_pagination p {
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  max-width: 300px;
  margin: 0px auto;
  margin-bottom: 25px;
} */

.register_pagination .next_buttn {
  padding-top: 68px;
  display: flex;
  justify-content: center;
}

.register_pagination .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#billing_info_sec .selct_input select {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

/* /////////////////////////////////////////////////////////////payment detail /////////////////////////////////////////// */

#payment_detail {
  background-color: var(--page-background-color-primary);
  /* padding: 64px 0px 73px 0px; */
}

/* #payment_detail  h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0px;
} */

#payment_detail .payment_sec > p {
  max-width: 670px;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  margin: 0px auto;
  margin-bottom: 50px;
}

#payment_detail .box_body {
  padding: 40px 51px 61px 44px;
}

#payment_detail .double_input {
  display: flex;
  justify-content: space-between;
}

#payment_detail .double_input > .input_container {
  width: calc(50% - 10px);
}

#payment_detail .payment_main {
  max-width: 620px;
  margin: 0px auto;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

/* #payment_detail .double_input .input_select {
  width: calc(50% - 10px);
} */

#payment_detail .double_input .input_select label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

#payment_detail .double_input .input_select input {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
}

#payment_detail .img_input {
  position: relative;
}

#payment_detail .box_body .img_input > img {
  position: absolute;
  right: 0px;
  top: 5px;
}

#payment_detail .double_input > .input_select img {
  top: 35px;
  right: 0px;
}

#payment_detail .register_pagination {
  padding-top: 38px;
}

/*//////////////////////////////////////////////////// Last signup page css :start/////////////////////////////////////////////////////////////// */

#final_signup {
  background-color: var(--page-background-color-primary);
  /* padding: 50px 100px; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: calc(80vh);
}

#final_signup .final_container {
  max-width: 900px;
  padding: 60px 25px 100px 25px;
  text-align: center;
  margin: 0px auto;
}

/* #final_signup h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  margin-bottom: 50px;
} */

#final_signup p {
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  margin-bottom: 50px;
}

#final_signup button {
  background: var(--text-color);
  border-radius: 30px;
  font-weight: 700;
  font-size: 21px;
  line-height: 100px;
  text-align: center;
  color: var(--white);
  width: 800px;
  border: none;
  cursor: pointer;
  margin-top: 90px;
}

#payment_detail .double_input {
  justify-content: inherit !important;
  gap: 10px;
}

#payment_detail .double_input > .input_container {
  width: 50%;
}

#payment_detail .double_input .selct_input {
  width: 50%;
  margin-bottom: 0px;
}

#payment_detail .double_input .selct_input select {
  width: 100%;
}

#payment_detail .selct_input select,
#payment_detail .double_input .input_select input {
  font-size: 16px !important;
  font-weight: 600;
}

#billing_info_sec .selct_input {
  margin-top: 30px;
  margin-bottom: 0px;
}

#payment_detail .double_input .input_select.img_input {
  width: calc(50% - 10px);
}

#billing_info_sec .info_bill h2 {
  color: #000;
  text-align: center;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  margin-top: 0px;
}

/* Media Queries */

@media screen and (max-width: 1200px) {
  .payment_main .input_container input {
    font-size: 17px;
  }
  #payment_detail .selct_input select,
  #payment_detail .double_input .input_select input {
    font-size: 16px !important;
    font-weight: 400;
  }
  #payment_detail .box_body .selct_input > label {
    font-size: 17px;
  }

  #payment_detail .double_input .selct_input {
    margin-top: 10px;
  }
  /* BillingInfo Media Queries for screens under 1200px    */
  #first_signup .login {
    width: 65%;
  }

  #final_signup .first_form {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #first_signup .carousel_sendmsg_div {
    width: 35%;
  }

  #first_signup .account_inputs {
    margin-top: 20px;
  }

  #billing_info_sec .billing_inner .box_body {
    padding: 30px;
  }

  .register_pagination .next_buttn {
    padding-top: 40px;
  }

  .register_pagination .next_buttn button {
    font-size: 19px;
    line-height: 25px;
    padding: 10px 50px;
  }

  #billing_info_sec .register_pagination {
    padding-top: 40px;
  }

  /* PaymentDetails Media Queries for screens under 1200px    */

  #payment_detail .box_body {
    padding: 30px;
  }

  #payment_detail .double_input > .input_select img {
    top: 10px;
  }

  #payment_detail .double_input .input_select label {
    margin-top: 9px;
    margin-bottom: 9px;
    font-size: 19px;
  }

  #payment_detail .img_input img {
    right: 0;
  }

  #payment_detail .img_input {
    margin-top: 7px;
  }

  #payment_detail .payment_sec > p {
    font-size: 19px;
    margin-bottom: 40px;
  }

  #payment_detail .double_input .input_select input {
    font-size: 19px;
    min-height: 40px;
    line-height: unset;
  }

  /* FinalSignup Media Queries for screens under 1200px    */

  #final_signup p {
    font-size: 19px;
  }

  #final_signup a {
    text-align: center;
    width: 100%;
  }

  #final_signup button {
    font-size: 19px;
    margin-top: 70px;
    line-height: 40px;
    width: auto;
    padding: 15px 40px;
  }
}

@media screen and (max-width: 1024px) {
  #billing_info_sec .selct_input {
    margin-top: 7px;
  }
  #billing_info_sec .selct_input label {
    font-size: 17px !important;
  }

  #billing_info_sec .selct_input select {
    margin-top: 7px !important;
    font-size: 17px;
    font-weight: 500;
  }

  /* BillingInfo Media Queries for screens under 1024px    */

  #billing_info_sec .billing_inner .box_body {
    padding: 20px;
    padding-bottom: 30px;
  }

  .register_pagination .next_buttn {
    padding-top: 30px;
  }

  .register_pagination .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }

  #billing_info_sec .register_pagination {
    padding-top: 30px;
  }

  /* PaymentDetails Media Queries for screens under 1024px    */
  #payment_detail .box_body {
    padding: 20px;
  }

  #payment_detail .double_input .input_select label {
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 17px;
  }

  #payment_detail .img_input img {
    right: 0;
  }

  #payment_detail .img_input {
    margin-top: 7px;
  }

  #payment_detail .payment_sec > p {
    font-size: 17px;
    margin-bottom: 25px;
    line-height: 25px;
  }

  #payment_detail .double_input .input_select input {
    font-size: 17px;
    min-height: 40px;
    line-height: unset;
  }

  #payment_detail .register_pagination {
    padding-top: 30px;
  }

  /* FinalSignup Media Queries for screens under 1024px    */

  #final_signup p {
    font-size: 17px;
  }

  #final_signup button {
    font-size: 17px;
    margin-top: 60px;
    line-height: 30px;
    width: auto;
    padding: 15px 50px;
  }

  #first_signup .login {
    width: 60%;
  }

  #first_signup .carousel_sendmsg_div {
    width: 40%;
  }

  #first_signup .with_google_btn > p {
    font-size: 15px;
    line-height: normal;
  }

  #first_signup .with_google_btn button {
    font-size: 17px;
    line-height: 25px;
  }

  #first_signup .check_box input[type="checkbox"] + label:before {
    width: 16px;
    height: 16px;
  }

  #first_signup .check_box input[type="checkbox"] + label {
    font-size: 16px;
  }

  #first_signup .checkbox_sec button {
    padding: 12px 30px;
    margin: 15px 0px;
  }
}

@media screen and (max-width: 767px) {
  #billing_info_sec .info_bill h2 {
    font-size: 26px;
  }
  #final_signup {
    height: 50vh;
  }
  #payment_detail .double_input .input_select.img_input {
    width: 100%;
  }

  #payment_detail .double_input {
    gap: 0px;
  }

  #payment_detail .double_input .selct_input select {
    min-height: 35px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
  }

  #payment_detail .double_input .selct_input {
    width: 100%;
    margin: 10px 0px;
    margin-bottom: 0px;
  }

  #payment_detail .box_body .selct_input > label {
    font-size: 14px;
  }

  /* BillingInfo Media Queries for screens under 767px    */

  #billing_info_sec .billing_inner {
    flex-direction: column;
  }

  #billing_info_sec .billing_one {
    width: 100%;
    margin: 0 !important;
  }

  #billing_info_sec .billing_inner .box_body {
    padding: 15px;
    padding-bottom: 28px;
  }

  #billing_info_sec .billing_one:nth-child(2) {
    padding-top: 20px !important;
  }

  #billing_info_sec .billing_inner .box_body > .selct_input label {
    margin-top: 5px !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  #billing_info_sec .selct_input select {
    margin-top: 5px !important;
    font-size: 14px !important;
    min-height: 35px;
    margin-bottom: 5px !important;
  }

  #billing_info_sec .selct_input select option {
    font-size: 14px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  #billing_info_sec .register_pagination {
    padding-top: 20px;
  }

  .register_pagination .next_buttn {
    padding-top: 20px;
  }

  .register_pagination .next_buttn button {
    font-size: 14px;
    line-height: 25px;
    padding: 5px 30px;
  }

  /* PaymentDetails Media Queries for screens under 767px    */

  #payment_detail .box_body {
    padding: 15px;
  }

  #payment_detail .double_input {
    flex-direction: column;
  }

  #payment_detail .double_input .input_container {
    width: 100%;
  }

  #payment_detail .double_input .input_select {
    width: 100%;
  }

  #payment_detail .double_input .input_select label {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  #payment_detail .img_input img {
    right: 0;
  }

  #payment_detail .img_input {
    margin-top: 5px;
  }

  #payment_detail .double_input .input_select input {
    font-size: 14px;
    min-height: 35px;
    line-height: unset;
    font-weight: normal;
  }

  #payment_detail .payment_sec > p {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
  }

  #payment_detail .register_pagination {
    padding-top: 20px;
  }

  /* FinalSignup Media Queries for screens under 767px    */

  #final_signup p {
    font-size: 14px;
  }

  #final_signup button {
    font-size: 14px;
    margin-top: 40px;
    line-height: 29px;
    width: auto;
    padding: 10px 20px;
  }

  #first_signup .login {
    width: auto;
    padding: 50px 15px;
  }

  #first_signup .carousel_sendmsg_div {
    width: auto;
  }

  #first_signup .account_inputs {
    margin-top: 20px;
  }

  #first_signup .container_sec {
    display: block;
    width: auto;
  }

  #first_signup .checkbox_sec button {
    margin-top: 0px;
    padding: 10px 25px;
  }

  #first_signup .checkbox_sec button a {
    font-size: 16px;
  }

  #first_signup .with_google_btn::before {
    font-size: 16px;
  }

  .hint_login > p {
    font-size: 14px;
    line-height: 20px;
  }

  .inner_container_login {
    width: 100%;
  }
}

.payment_detail_box_body > label {
  display: none;
}

.stripe_my_element label {
  margin-bottom: 10px !important;
  display: block;
}

.stripe_my_element input,
.stripe_my_element .StripeElement {
  display: block;
  padding: 9px 10px;
  outline: 0;
  padding-top: 12px;
  border: 2px solid var(--text-color);
  border-radius: 8px;
  background: var(--input-bg);
  min-height: 6px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.stripe_my_element input::placeholder {
  color: #aab7c4;
}

.stripe_my_element input:focus,
.stripe_my_element .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe_my_element .StripeElement.IdealBankElement,
.stripe_my_element .StripeElement.FpxBankElement,
.stripe_my_element .StripeElement.PaymentRequestButton {
  padding: 0;
}

.payment_main .checkbox_main {
  margin-top: 22px;
}

@media screen and (max-width: 1200px) {
  .stripe_my_element input,
  .stripe_my_element .StripeElement {
    font-size: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .stripe_my_element input,
  .stripe_my_element .StripeElement {
    font-size: 19px;
    min-height: 0;
    font-weight: 400;
  }
  .payment_main .checkbox_main {
    margin-top: 18px;
  }
}

@media screen and (max-width: 767px) {
  .stripe_my_element input,
  .stripe_my_element .StripeElement {
    font-size: 14px;
    min-height: 6px;
    font-weight: 400;
    padding-top: 7px;
  }
  .payment_main .checkbox_main {
    margin-top: 12px;
  }
}

.stripe_my_element .invalid-error {
  margin-top: 3px;
}
