@import "../../constants/colors.css";

#dashboard {
  background-color: var(--page-background-color-primary);
  /* padding: 70px 0px; */
}

/* #dashboard h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 80px;
  margin-top: 0px;
} */

#dashboard .dashboard_inner {
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  margin-bottom: 45px;
  margin-top: 75px;
}

#dashboard .forms_body {
  padding: 20px 30px 15px 40px;
  margin-bottom: 5px;
}

#dashboard .forms_body table {
  width: 100%;
}

#dashboard .forms_body table tr th {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--text-color);
  text-align: left;
  max-width: 70px;
  padding: 15px 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid var(--line-divider);
}

#dashboard .forms_body table {
  border-collapse: collapse;
}

#dashboard .forms_body table tr {
  border-bottom: 1px solid var(--line-divider);
}

#dashboard .forms_body table tr td {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  padding: 14px 10px;
}

#dashboard .forms_body table tr:last-child {
  border-bottom: none;
}

#dashboard .forms_body table tr td button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

#dashboard .forms_body table tr .manage_button button {
  background: var(--or-text);
  padding: 10px 15px;
  /* font-size: 18px;
  min-width: 200px; */
}

#dashboard .forms_body table tr .Edit_button button {
  border: none;
  background: var(--text-color);
  padding: 10px 14px;
  margin: 0px 3px;
  font-size: 18px;
}

#dashboard .forms_body table tr .cancel_button button {
  background: var(--box-header-linear-primary);
  padding: 10px 24px;
}

#dashboard .forms_body table tr .manage_button,
#dashboard .forms_body table tr .Edit_button,
#dashboard .forms_body table tr .cancel_button {
  text-align: right;
}

#dashboard .payment_sec {
  display: flex;
  width: 100%;
  /* margin-bottom: 70px; */
}

#dashboard .payment_sec .payment_sec1 {
  width: calc(50% - 20px);
  margin-right: 20px;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#dashboard .payment_sec .payment_sec2 {
  width: calc(50% - 20px);
  margin-left: 20px;
  text-align: center;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#dashboard .payment_sec .payment_body {
  padding: 40px 0px;
}

#dashboard .payment_sec .inner_image {
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  max-width: 140px;
  height: 140px;
  margin: 0px auto;
  position: relative;
}

#dashboard .payment_sec .inner_image .img_py_sec,
.crm_sec .content_box .content1 .img_py_sec {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}

#dashboard .payment_sec .inner_image .img_py_sec img {
  width: 100%;
}

/* 
#dashboard .payment_sec .inner_image.hubspot img {
  left: 50%;
  transform: translateX(-50%);
  top: 40%;
} */

#dashboard .payment_sec .payment_body button {
  border: none;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  padding: 15px 20px;
  color: var(--white);
  background: var(--text-color);
  border-radius: 30px;
  margin-top: 35px;
  cursor: pointer;
}

#dashboard .payment_sec .payment_body:nth-child(2) button {
  padding: 15px 30px;
}

#dashboard .content_no_crm {
  display: flex;
  align-items: center;
  justify-content: center;
}

#dashboard .content_no_crm p {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
}

/* manage payment css start */

.manage_payment_sec {
  background-color: var(--page-background-color-primary);
  /* padding: 60px 0px 100px 0px; */
}

.manage_payment_sec .manage_payment {
  max-width: 1285px;
  margin: 0px auto;
}

/* .manage_payment_sec .manage_payment h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 70px;
} */

.manage_payment_sec .manage_payment .payment_section {
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  background-color: var(--white);
}

.manage_payment_sec .manage_payment .payment_body {
  padding: 0px 35px;
  background-color: var(--white);
  border-radius: 0 0 10px 10px;
  padding-bottom: -5px;
  margin-bottom: 5px;
}

.manage_payment_sec .manage_payment .payment_body table {
  width: 100%;
  border-collapse: collapse;
}

.manage_payment_sec .manage_payment .payment_body table tr th {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--text-color);
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 65px;
}

.manage_payment_sec .manage_payment .payment_body table tr {
  border-bottom: 1px solid var(--line-divider);
}

.manage_payment_sec .manage_payment .payment_body table tr td {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  text-align: left;
  padding: 20px 0px;
}

.manage_payment_sec .manage_payment .payment_body table tr #success_button {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  text-align: left;
  padding: 20px 0px;
}

.manage_payment_sec .manage_payment .payment_body table tr:last-child {
  border-bottom: none;
}

.manage_payment_sec button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  border: none;
  border-radius: 30px;
  color: var(--white);
  cursor: pointer;
}

.manage_payment_sec .manage_payment .payment_body table tr .Edit_button button {
  background: var(--ul-background);
  padding: 10px 29px;
}

.manage_payment_sec
  .manage_payment
  .payment_body
  table
  tr
  .cancel_button
  button {
  background: var(--box-header-linear-primary);
  padding: 10px 45px;
}

.manage_payment_sec
  .manage_payment
  .payment_body
  table
  tr
  #success_button
  button {
  background: var(--success);
  padding: 10px 29px;
}

.manage_payment_sec
  .manage_payment
  .payment_body
  table
  tr
  #cancelled_button
  button {
  background: var(--error);
  padding: 10px 29px;
  font-size: 20px;
}

.manage_payment_sec .manage_payment .payment_body table tr:last-child td {
  border-bottom: none;
}

.manage_payment_sec .back_buttn {
  margin: 70px 0px 30px 0px;
  text-align: center;
}

.manage_payment_sec .back_buttn button {
  background: var(--ul-background);
  border-radius: 30px;
  padding: 15px 70px;
  color: white;
}

/* css for my payment forms page table ui   */
#dashboard .forms_body table tr td:nth-child(4) {
  max-width: 200px;
}

/*///////////////////////////////////////////////////// manage payment css End /////////////////////////////////////////////////////////////////////////////*/

/*///////////////////////////////////////////////////// Crm css Start /////////////////////////////////////////////////////////////////////////////*/

.crm_sec,
.Paymentedit_sec {
  background: var(--page-background-color-primary);
  /* padding: 60px 0px 90px 0px; */
}

.crm_sec .crm_main,
.Paymentedit_sec .crm_main {
  max-width: 1285px;
  margin: 0px auto;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  margin-top: 75px;
}
.crm_sec .crm_main .back_btn_primary {
  text-align: center;
}

.crm_sec .crm_main .back_btn_primary button {
  background: var(--orange-button-background);
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  border: none;
}

.crm_sec .crm_body {
  padding: 50px 25px;
  background-color: var(--white);
  border-radius: 0px 0px 10px 10px;
}

.crm_sec .crm_body h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
}

.crm_sec .content_box {
  display: flex;
  /* flex-wrap: wrap; */
  padding: 50px 0px 100px 0px;
  gap: 44px;
  justify-content: center;
}

.crm_sec .content_box .content {
  width: 220px;
  height: 220px;
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.crm_sec .content_box .content img {
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
  width: 100%;
}

.crm_sec .content_box .content1 img {
  left: 28%;
}

.crm_sec .content_box .content2 img {
  left: 23%;
}

.crm_sec .content_box .content3 img {
  left: 18%;
}

.crm_sec .content_box .content4 img:first-child {
  left: 28%;
  top: 38%;
}

.crm_sec .content_box .content4 img:last-child {
  left: 20%;
  top: 80%;
}

.crm_sec .content_box .content:nth-child(5) {
  margin-right: 0px;
}

.crm_sec .content_box .content5 img:first-child {
  left: 28%;
  top: 40%;
}

.crm_sec .content_box .content5 img:last-child {
  left: 20%;
  top: 75%;
}

.crm_sec .content_no_crm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crm_sec .content_no_crm p {
  font-size: 18px;
  color: var(--text-color);
  text-align: center;
  font-weight: 600;
}

.Paymentedit_sec .content_box .img_py_sec img {
  max-width: 160px;
}

.crm_sec .content_box .content2 .img_py_sec {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.Paymentedit_sec .content_box {
  justify-content: center;
}

.Paymentedit_sec .content_box .content1 img {
  left: 18%;
}

.Paymentedit_sec .content_box .content2 img {
  left: 17px;
}

.Paymentedit_sec .content_box .content3 img {
  left: 8%;
}

.Paymentedit_sec .content_box .content4 img:last-child {
  left: 10%;
  top: 70%;
}

.Paymentedit_sec .content_box .content4 img:first-child {
  left: 34%;
  top: 38%;
}

#dashboard .forms_body::-webkit-scrollbar-track,
.manage_payment_sec .payment_body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

#dashboard .forms_body::-webkit-scrollbar,
.manage_payment_sec .payment_body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#dashboard .forms_body::-webkit-scrollbar-thumb,
.manage_payment_sec .payment_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  background-color: #ccc;
}

#dashboard .react_pagination {
  padding: 20px 0px;
  height: auto !important;
}

#dashboard .forms_body table tr .Edit_button {
  justify-content: flex-end;
}

/* //////////////////////////////////////////////////////media quarry started/////////////////////////////////////////////// */

@media screen and (max-width: 1400px) {
  #dashboard .forms_body table tr .Edit_button {
    display: flex;
  }
  #dashboard .forms_body table tr .Edit_button button {
    padding: 6px 12px;
    font-size: 16px;
    white-space: nowrap;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    .Edit_button
    button {
    padding: 0px;
    width: 130px;
    height: 50px;
    font-size: 17px;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    .cancel_button
    button {
    padding: 0px;
    width: 130px;
    height: 50px;
    font-size: 17px;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    #success_button
    button {
    padding: 0px;
    width: 130px;
    height: 50px;
    font-size: 17px;
    cursor: default;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    #cancelled_button
    button {
    padding: 0px;
    width: 130px;
    height: 60px;
    font-size: 17px;
    cursor: default;
  }
}

@media screen and (max-width: 1300px) {
  .crm_sec .content_box {
    flex-wrap: wrap;
  }

  .manage_payment_sec .manage_payment .payment_body table tr th {
    padding-right: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .crm_sec .crm_main,
  .Paymentedit_sec .crm_main {
    margin-top: 15px;
  }
  .crm_sec .crm_body h2 {
    font-size: 32px;
  }

  .crm_sec .content_box {
    padding: 50px;
  }

  #dashboard .dashboard_inner {
    margin-top: 15px;
  }

  #dashboard .forms_body table tr td button {
    width: 102px !important;
    min-height: 42px;
    padding: 0px !important;
    font-size: 16px;
    line-height: 23px;
  }

  #dashboard .forms_body table tr td {
    font-size: 16px;
  }

  #dashboard .forms_body table tr th {
    padding: 10px 5px;
    font-size: 16px;
  }

  #dashboard .payment_sec .payment_body button {
    font-size: 19px;
  }

  .manage_payment_sec .manage_payment .payment_body table tr th {
    font-size: 17px;
    padding-top: 18px;
    padding-bottom: 10px;
  }

  .manage_payment_sec .manage_payment .payment_body table tr td {
    padding: 12px 0px;
    font-size: 17px;
  }

  .manage_payment_sec .manage_payment .payment_body {
    overflow-x: scroll;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    .Edit_button
    button {
    margin-right: 10px;
  }

  #dashboard .forms_body {
    overflow-x: scroll;
  }

  #dashboard .forms_body::-webkit-scrollbar,
  .manage_payment_sec .payment_body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

@media screen and (max-width: 767px) {
  .crm_sec .crm_main .back_btn_primary button {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 30px;
  }

  #dashboard .react_pagination {
    gap: 5px;
  }

  .crm_sec .crm_body h2 {
    font-size: 24px;
  }

  .crm_sec .content_box {
    padding: 20px;
  }

  .crm_sec .crm_body {
    padding: 20px 10px;
  }

  .crm_sec .content_box .content {
    width: 160px;
    height: 150px;
  }

  .crm_sec .content_box .content img {
    max-width: 110px;
  }

  .Paymentedit_sec .crm_body .content_box .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Paymentedit_sec .crm_body .content_box .content img,
  .Paymentedit_sec .content_box .content img {
    position: unset;
    transform: unset;
  }

  .Paymentedit_sec .content_box .content4 img:last-child {
    margin-top: 10px;
  }

  .Paymentedit_sec .crm_body .content_box .content4 {
    flex-direction: column;
  }

  .crm_sec .content_box .content1 img {
    left: 23%;
  }

  .crm_sec .content_box .content2 img {
    left: 18%;
  }

  .crm_sec .content_box .content3 img {
    left: 16%;
  }

  .crm_sec .content_box .content4 img:first-child {
    left: 18%;
    top: 30%;
  }

  .crm_sec .content_box .content4 img:last-child {
    left: 18%;
    top: 82%;
  }

  .crm_sec .content_box .content5 img:first-child {
    left: 25%;
    top: 40%;
  }

  .crm_sec .content_box .content5 img:last-child {
    left: 18%;
    top: 83%;
  }

  #dashboard .payment_sec {
    flex-direction: column;
  }

  #dashboard .payment_sec .payment_sec1 {
    width: 100%;
    margin-bottom: 20px;
  }

  #dashboard .payment_sec .payment_sec2 {
    width: 100%;
    margin-left: 0px;
  }

  #dashboard .payment_sec .payment_body:nth-child(2) button {
    padding: 10px 30px;
  }

  #dashboard .payment_sec .payment_body button {
    font-size: 15px;
  }

  #dashboard .forms_body table tr th {
    font-size: 14px;
  }

  #dashboard .forms_body table tr td {
    font-size: 14px;
  }

  #dashboard .forms_body table tr td button {
    width: 105px !important;
    min-height: 47px;
    padding: 0px !important;
    font-size: 14px;
    line-height: 18px;
  }

  #dashboard .payment_sec .inner_image img {
    left: 13%;
    max-width: 110px;
  }

  #dashboard .payment_sec .payment_body button {
    font-size: 15px;
  }

  .manage_payment_sec .manage_payment .payment_body table tr th {
    padding: 0px;
    min-width: 100px;
  }

  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    .Edit_button
    button,
  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    #success_button
    button,
  .manage_payment_sec
    .manage_payment
    .payment_body
    table
    tr
    .cancel_button
    button {
    font-size: 15px;
    height: 40px;
  }

  .manage_payment_sec .back_buttn {
    margin: 40px 0px 20px 0px;
  }

  .manage_payment_sec button {
    font-size: 16px;
    line-height: normal;
  }

  .manage_payment_sec .back_buttn button {
    padding: 12px 50px;
  }

  .manage_payment_sec .manage_payment .payment_body table tr th {
    font-size: 16px;
    padding-top: 10px;
  }

  .manage_payment_sec .manage_payment .payment_body table tr td {
    padding: 10px 0px;
    font-size: 15px;
  }

  #dashboard .forms_body {
    overflow-x: scroll;
    padding: 20px 10px;
  }

  .manage_payment_sec .manage_payment .payment_body {
    padding: 15px;
    padding-top: 20px;
  }

  #dashboard .forms_body::-webkit-scrollbar,
  .manage_payment_sec .payment_body::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  #dashboard .forms_body table tr .Edit_button button {
    font-size: 14px !important;
    min-height: 37px;
  }
}

.nothing_selected {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-weight: 600;
}

.inactive_form {
  background-color: var(--error);
  border-radius: 20px;
  padding: 5px 7px;
  color: var(--white);
  font-size: 15px !important;
  font-weight: unset !important;
}

.active_form {
  background-color: var(--success);
  border-radius: 20px;
  padding: 5px 7px;
  color: var(--white);
  font-size: 15px !important;
  font-weight: unset !important;
}
