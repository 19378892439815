@import "../constants/colors.css";

div.login {
  min-height: calc(50vh);
}

.container {
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0px auto;
}

.page_container {
  max-width: 1285px;
  margin: 0px auto;
  padding: 64px 78px 124px 77px;
}

a {
  text-decoration: none;
  display: block;
}

h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 29px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 80px;
  margin-top: 0px;
}

/*/////////////////////////////////////// testimonial css ///////////////////////////////// */

.testimonial .carousel .slide .legend {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
  font-size: 20px;
}

.testimonial .carousel-root {
  width: 100%;
}

.testimonial .testimonial_item svg {
  position: absolute;
  left: 20px;
  top: 10px;
  color: var(--white);
}

.testimonial .carousel.carousel-slider {
  overflow: inherit;
}

.testimonial .carousel .testimonial_item {
  background: transparent;
  transition: inherit;
  color: var(--white);
  opacity: 1;
  font-size: 20px;
}

.testimonial .carousel .testimonial_item p {
  max-width: 80%;
  margin: 0px auto;
  text-align: justify;
  color: var(--white);
}

.testimonial .carousel .control-dots {
  bottom: -50px;
  z-index: 100;
  position: absolute;
}

.testimonial .carousel .control-dots li {
  background-color: var(--white);
  opacity: 0.5;
  color: var(--white);
  width: 10px;
  height: 10px;
}
.testimonial .carousel .testimonial_item .img_card_test {
  width: 20%;
  max-width: 22%;
  height: auto;
  margin: 20px auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: var(--info-toast) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: var(--success-toast) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: var(--error-toast) !important;
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 30px !important;
    margin-bottom: 50px !important;
  }

  .page_container {
    padding: 50px 45px 65px 45px;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 30px !important;
    margin-bottom: 30px !important;
  }

  .page_container {
    padding: 44px 40px 60px 40px;
  }

  /* testimonial css started */

  .testimonial .carousel .testimonial_item P {
    font-size: 17px;
  }

  .carousel_sendmsg_div .msg_btn > button {
    font-size: 18px;
  }

  .testimonial {
    padding: 100px 10px 0 10px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 26px !important;
    margin-bottom: 25px !important;
  }

  .page_container {
    padding: 25px 15px;
  }

  .container {
    padding: 0px 15px !important;
  }

  /* testimonial css start */

  .testimonial {
    padding: 20px 10px 0 10px !important;
  }

  .carousel_sendmsg_div .msg_btn > button {
    margin-top: 70px;
  }

  .testimonial .testimonial_item .svg-inline--fa {
    font-size: 18px;
  }

  .testimonial .carousel .testimonial_item P {
    font-size: 15px;
    line-height: 22px;
  }

  .testimonial .carousel.carousel-slider .control-arrow:hover {
    background-color: transparent;
  }

  .carousel_sendmsg_div .msg_btn > button {
    font-size: 16px !important;
    /* padding-right: 40px !important; */
    padding: 8px 40px 8px 8px !important;
  }

  .testimonial .carousel .control-dots li {
    width: 8px !important;
    height: 8px !important;
  }

  .testimonial .testimonial_item h3 {
    font-size: 21px;
    margin: 15px 0px;
  }
}
