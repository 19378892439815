 .check_box_remember {
    padding: 30px 0 20px 0;
    display: flex;
    align-items: center;
  }
  
   .check_box_remember input[type="checkbox"] + label {
    display: block;
    cursor: pointer;
    font-family: "Lato";
    color: var(--text-color);
  }
  
   .check_box_remember > label {
    font-size: 21px;
  }
  
   .check_box_remember input[type="checkbox"] {
    border: 0.1em solid var(--text-color);
    width: 20px;
    height: 20px;
    /* position: relative; */
    margin-right: 10px;
  }
  
   .check_box_remember input[type="checkbox"]:checked {
    appearance: none;
    background-color: var(--text-color);
    border-radius: 2px;
    /* transform: scale(0); */
  }
  
   .check_box_remember input[type="checkbox"]:checked:before {
    content: "";
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 0.15em;
    vertical-align: bottom;
    color: transparent;
    transition: 0.3s;
    background-color: var(--white);
    position: absolute;
    left: 22%;
    top: 42%;
    transform: translate(-50%);
    transform: rotate(-45deg);
  }
  
   .check_box_remember input[type="checkbox"]:checked::after {
    content: "";
    border-radius: 0.2em;
    display: inline-block;
    width: 0.4em;
    height: 0.15em;
    vertical-align: bottom;
    color: transparent;
    transition: 0.3s;
    background-color: var(--white);
    position: absolute;
    left: 12%;
    top: 53%;
    transform: translate(-50%);
    transform: rotate(45deg);
  }
  
   .check_box_remember input[type="checkbox"] + label:active:before {
    transform: scale(0);
  }