@import "../constants/colors.css";

.input_container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

.input_container > input {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

.input_container > input:focus {
  color: var(--input-focus-color);
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus);
  outline: 0;
}

.invalid-error {
  color: var(--error);
  font-size: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .input_container {
    padding: 0 !important;
  }
  .input_container label {
    margin-top: 9px !important;
    font-size: 19px !important;
    margin-bottom: 9px !important;
  }
  .input_container input {
    font-size: 19px;
    min-height: 40px;
    font-weight: 400;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .input_container {
    padding: 0 !important;
  }
  .input_container label {
    margin-top: 7px !important;
    font-size: 17px !important;
    margin-bottom: 7px !important;
  }
  .input_container input {
    font-size: 17px;
    min-height: 40px;
    font-weight: 400;
  }
}

@media screen and (max-width: 767px) {
  .input_container {
    padding: 0 !important;
  }
  .input_container label {
    margin-top: 5px !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
  .input_container input {
    font-size: 14px;
    min-height: 35px;
    font-weight: 400;
  }
}
