.custom-toast {
  padding: 1px 10px;
  /* margin: 0 20px; */
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus-2);
  z-index: 9999;
  background-color: var(--white);
}
.custom-toast > p {
  color: var(--text-color);
  font-weight: 400;
  font-size: 21px;
  line-height: 17px;
}

.custom-toast.success {
  border-left: 4px solid var(--success);
}

.custom-toast.error {
  border-left: 4px solid var(--error);
}

.custom-toast.message {
  border-left: 4px solid var(--message);
}


@media screen and (max-width:767px) {
  .custom-toast > p {
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    padding: 10px 0px;
  }
  .custom-toast{
    margin: 0px;
  }
}