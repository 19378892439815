@import "../../../constants/colors.css";

#register {
  /* padding: 40px 0px 90px 0px; */
  background-color: var(--page-background-color-primary);
}

/* #register .register_sec h1 {
    max-width: 800px;
    margin: 0px auto;
    text-align: center;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: var(--black);
} */

#register .contact_information {
  background: var(--white);
  border: 1px solid var(--form-code-background);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  margin-top: 30px;
  max-width: 1230px;
  margin: 30px auto 0px auto;
  /* padding-bottom: 130px; */
  box-sizing: border-box;
}

#register .contact_information .contact_body {
  padding: 34px 59px 176px 44px;
  display: block;
}

/* #register .contact_information .contact_body label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
} */

/* #register .contact_information .contact_body input {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
} */

#register .pagination_outer {
  padding-top: 130px;
  text-align: center;
}

#register .next_buttn {
  padding-top: 50px;
}

#register .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

@media screen and (max-width: 1200px) {
  #register .contact_information .contact_body {
    padding: 30px !important;
  }
  #register .contact_information {
    padding-bottom: 0 !important;
  }

  #register .pagination_outer {
    padding-top: 40px;
  }

  #register .pagination_outer .next_buttn {
    padding-top: 40px;
  }
  #register .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 29px;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  #register .contact_information .contact_body {
    padding: 20px !important;
  }
  #register .contact_information {
    padding-bottom: 0 !important;
  }

  #register .pagination_outer {
    padding-top: 30px;
  }

  #register .pagination_outer .next_buttn {
    padding-top: 30px;
  }
  #register .pagination_outer .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  #register .contact_information .contact_body {
    padding: 15px !important;
  }
  #register .contact_information {
    padding-bottom: 0 !important;
  }

  #register .pagination_outer {
    padding-top: 20px;
  }

  #register .pagination_outer .next_buttn {
    padding-top: 20px;
  }
  #register .pagination_outer .next_buttn button {
    font-size: 14px;
    padding: 5px 30px;
  }
}
