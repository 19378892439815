.box_header {
  background: linear-gradient(
    270deg,
    var(--linear-primary) 0.11%,
    var(--linear-secondary) 99.91%
  );
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 20px 25px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
}

.box_header img {
  margin-right: 18px;
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .box_header {
    font-size: 22px;
  }
  .box_header img {
    width: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .box_header {
    font-size: 20px;
    padding: 15px 25px;
  }
  .box_header img {
    width: 35px;
  }
}

@media screen and (max-width: 767px) {
  .box_header {
    font-size: 18px;
    padding: 10px 15px;
  }
  .box_header img {
    width: 25px;
  }
}
