.lost_password_sec {
    background-color: #fff;
    padding: 60px 100px;
}

.lost_password_sec .forgot {
    max-width: 500px;
    margin: 0px auto;
    padding: 30px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.lost_password_sec .forgot label {
    margin-top: 5px;
}

.lost_password_sec .forgot button {
    background: var(--primary-authBtn);
    border: none;
    border-radius: 3px;
    box-shadow: 1px 2px 4px var(--boxshadow-primary-authBtn);
    cursor: pointer;
    padding: 15px 30px;
    margin: 30px 0px;
    color: #fff;
    font-size: 20px;

}

.logi_register ul {
    display: flex;
    padding-left: 0px;
    border-top: 1px dotted #999;
    padding-top: 15px;
    margin: 0px auto;
}

.logi_register ul a {
    text-decoration: none;

}

.logi_register ul li:nth-child(1) {
    padding-left: 0px;
}

.logi_register ul li {
    list-style: none;
    color: #6d6d6d;
    padding: 0px 10px;
    font-size: 18px;
}

.logi_register ul a:nth-child(2) li {
    padding-left: 10px;
    border-left: 1px solid #999;
}



/* media quarry started//////////////////////////////////////////////////////// */


@media screen and (max-width:767px) {
    .lost_password_sec {
        padding: 40px 0px;
    }

    .lost_password_sec .forgot button {
        margin-left: 0px;
        font-size: 16px;
        padding: 15px 20px;
        margin: 20px 0px;
    }

    .lost_password_sec .forgot {
        padding: 30px 10px;
    }

    .logi_register ul li {
        font-size: 16px;
    }
}