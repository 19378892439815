@import "../../constants/colors.css";

#setup_product_main {
  background-color: var(--page-background-color-primary);
}

#setup_product_main .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#setup_product_main .form_head {
  padding-bottom: 74px;
}

#setup_product_main .form_bodies {
  display: flex;
  justify-content: space-between;
}

#setup_product_main .form_bodies .forms_left,
.forms_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 20px);
}

#setup_product_main .form_bodies .forms_left {
  padding-right: 20px;
}

#setup_product_main .form_bodies .forms_right {
  padding-left: 20px;
  height: 100%;
}

#setup_product_main .sales_tax,
#setup_product_main .form_bodies .forms_right .shipping_address,
#setup_product_main .form_bodies .forms_left .form_type,
#setup_product_main .form_bodies .forms_right .discount_codes {
  background-color: var(--white);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
}

#setup_product_main .sales_tax {
  /* width: calc(50% - 21px); */
  width: 100%;
}

#setup_product_main .sales_tax .input_container {
  margin: 0;
  padding: 0;
}

#setup_product_main .sales_tax .input_container label {
  margin: 0;
  padding: 0;
  padding-bottom: 13px;
}

#setup_product_main .form_bodies .forms_left .form_type .form_type_body {
  padding: 52px 30px 56px 52px;
  color: var(--white);
}

#setup_product_main .sales_tax .sales_tax_body {
  padding: 37px 52px 53px 48px;
}

#setup_product_main
  .form_bodies
  .forms_right
  .shipping_address
  .shipping_address_body {
  padding: 33px 50px 55px;
}

#setup_product_main
  .form_bodies
  .forms_right
  .discount_codes
  .discount_codes_body {
  padding: 37px 52px 52px 48px;
}

#setup_product_main .input_container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 13px;
}

#setup_product_main .disc_container .select_container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin: 0;
  margin-bottom: 13px;
}

#setup_product_main .select_container select {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

#setup_product_main .select_container select:focus {
  color: var(--input-focus-color);
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus-2);
  outline: 0;
}

#setup_product_main .save_btn {
  padding-top: 50px;
}

#setup_product_main .save_btn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#setup_product_main .discount_codes_body .disc_container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 12px;
  /* align-items: end; */
}

#setup_product_main .form_type_body .product_detail .product_name {
  display: flex;
  justify-content: space-between;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .product_name_div {
  width: 65%;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .product_name_div
  .input_container {
  padding: 0;
  margin: 7px 0 0 0;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .product_price_div
  .input_container {
  padding: 0 12px;
  /* margin: 7px 0; */
}

#setup_product_main .input_container {
  position: relative;
}

/* .invalid-error.invalid-feedback {
  position: absolute;
  bottom: -15PX;
  line-height: 10px;
} */
#setup_product_main .discount_codes_body .input_container .invalid-error {
  bottom: -21px;
}

#setup_product_main .discount_codes_body .disc_container .input_container {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#setup_product_main .discount_codes_body .disc_container .input_container {
  /* width: 75%; */
  width: 100%;
}

#setup_product_main
  .discount_codes_body
  .disc_container
  .percentage_inputBefore
  .input_container {
  width: 100%;
}

#setup_product_main .sales_tax .sales_tax_body .input_div {
  width: 20%;
}

#setup_product_main
  .discount_codes_body
  .disc_container
  .percentage_inputBefore {
  width: 25%;
  position: relative;
}

#setup_product_main
  .discount_codes_body
  .disc_container
  .percentage_inputBefore
  .invalid-error {
  position: absolute;
  bottom: -28px;
}

#setup_product_main
  .discount_codes_body
  .disc_container
  .input_container
  label {
  margin-top: 0px;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .product_price_div {
  width: 35%;
  display: flex;
}

#setup_product_main .form_type_body .product_detail .product_name .inc_div {
  display: flex;
  justify-content: center;
  margin-bottom: 9px;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .inc_div
  .inner {
  display: flex;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .inc_div
  .inner
  > button {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: var(--text-color);
  color: var(--white);
  height: 30px;
  width: 30px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

#setup_product_main
  .form_type_body
  .product_detail
  .product_name
  .inc_div
  .dec
  > button {
  background-color: var(--orange-decrement-background);
}

#setup_product_main .form_type_body .radio_main {
  padding: 0;
}

#setup_product_main .shipping_address_body > p {
  color: var(--text-color);
  font-size: 21px;
  padding: 0;
  padding-bottom: 8px;
  font-weight: 400;
  margin: 0;
  line-height: 29px;
}

#setup_product_main .pagination_outer {
  text-align: center;
  padding-top: 64px;
}

#setup_product_main .next_buttn {
  padding-top: 78px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.bck_next_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
  margin-top: 46px;
}

#setup_product_main .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

.product_detail .input_container {
  position: relative !important;
}

.product_detail .invalid-error.invalid-feedback {
  position: absolute;
}

.product_price_div.otherclas .input_container {
  width: 100% !important;
}

/* #setup_product_main .discount_codes_body .disc_container > .input_container input{
  width: 100% !important;
} */

/*///////////////////////////////////////// setupProduct Media Queries ////////////////////////////////////////////////////*/

@media screen and (max-width: 1200px) {
  #setup_product_main
    .discount_codes_body
    .disc_container
    .percentage_inputBefore
    .invalid-error {
    bottom: -15px;
  }
  #setup_product_main .sales_tax .sales_tax_body .input_div {
    width: 25%;
  }
  #setup_product_main .form_bodies {
    flex-direction: column;
  }

  /* #setup_product_main .sales_tax {
      width: 100%;
      margin: 0 !important;
    } */

  #setup_product_main .form_bodies .forms_left,
  .forms_right {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  #setup_product_main .form_bodies .forms_right {
    flex-direction: row !important;
    width: 100% !important;
    flex-wrap: wrap;
  }

  #setup_product_main .form_bodies .forms_left .form_type .form_type_body {
    padding: 30px;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .radio_main {
    margin-bottom: 20px;
  }

  #setup_product_main
    .form_type_body
    .product_detail
    .product_name
    .product_name_div {
    width: 60%;
  }

  #setup_product_main
    .form_type_body
    .product_detail
    .product_name
    .product_price_div {
    width: 40%;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
  }

  #setup_product_main .form_type_body .product_detail .product_name .inc_div {
    margin-bottom: 10px;
  }

  #setup_product_main .form_bodies > .forms_right > .discount_codes,
  .shipping_address {
    margin: 0 !important;
    /* margin-bottom: 20px !important; */
    width: calc(50% - 20px);
  }

  #setup_product_main .sales_tax {
    margin-top: 20px;
    width: calc(50% - 21px);
  }

  #setup_product_main
    .form_bodies
    .forms_right
    > .discount_codes
    > .discount_codes_body,
  .shipping_address > .shipping_address_body,
  #setup_product_main .sales_tax .sales_tax_body {
    padding: 30px !important;
  }

  #setup_product_main .discount_codes_body .disc_container {
    flex-direction: column;
  }

  #setup_product_main .discount_codes_body .disc_container .input_container {
    width: 100% !important;
  }

  #setup_product_main
    .form_bodies
    .forms_right
    .shipping_address
    > .shipping_address_body
    p {
    font-size: 17px !important;
  }

  #setup_product_main .pagination_outer {
    padding-top: 30px !important;
  }

  #setup_product_main .pagination_outer .next_buttn {
    padding-top: 40px;
  }

  #setup_product_main .pagination_outer .next_buttn button {
    /* font-weight: 500; */
    font-size: 19px;
    line-height: 29px;
    padding: 10px 50px;
  }
  #setup_product_main
    .discount_codes_body
    .disc_container
    .percentage_inputBefore {
    width: 20%;
  }
  #setup_product_main .sales_tax .sales_tax_body .input_div {
    width: 20%;
  }
}

@media screen and (max-width: 1024px) {
  #setup_product_main .form_type_body .product_detail .product_name {
    gap: 10px;
  }

  #setup_product_main .form_bodies {
    flex-direction: column;
  }

  #setup_product_main .form_bodies .forms_left,
  .forms_right {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  #setup_product_main .form_bodies .forms_right {
    flex-direction: row !important;
    width: 100% !important;
    flex-wrap: wrap;
  }

  #setup_product_main .form_bodies .forms_left .form_type .form_type_body {
    padding: 20px;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .radio_main {
    margin-bottom: 20px;
  }

  #setup_product_main
    .form_type_body
    .product_detail
    .product_name
    .product_name_div {
    width: 60%;
  }

  #setup_product_main
    .form_type_body
    .product_detail
    .product_name
    .product_price_div {
    width: 40%;
    display: flex;
    justify-content: space-around;
  }

  #setup_product_main .form_type_body .product_detail .product_name .inc_div {
    margin-bottom: 10px;
  }

  #setup_product_main .form_bodies > .forms_right > .discount_codes,
  .shipping_address {
    margin: 0 !important;
    width: calc(50% - 20px);
  }

  #setup_product_main .sales_tax {
    margin-top: 20px;
  }

  #setup_product_main
    .form_bodies
    .forms_right
    > .discount_codes
    > .discount_codes_body,
  .shipping_address > .shipping_address_body,
  #setup_product_main .sales_tax .sales_tax_body {
    padding: 20px !important;
  }

  #setup_product_main .discount_codes_body .disc_container {
    flex-direction: column;
  }

  #setup_product_main .discount_codes_body .disc_container .input_container {
    width: 100% !important;
  }

  #setup_product_main
    .form_bodies
    .forms_right
    .shipping_address
    > .shipping_address_body
    p {
    font-size: 17px !important;
  }

  #setup_product_main .pagination_outer {
    padding-top: 30px !important;
  }

  #setup_product_main .pagination_outer .next_buttn {
    padding-top: 30px;
  }

  #setup_product_main .pagination_outer .next_buttn button {
    /* font-weight: 500; */
    font-size: 19px;
    line-height: 25px;
    padding: 10px 40px;
  }
  #setup_product_main
    .discount_codes_body
    .disc_container
    .percentage_inputBefore {
    width: 25%;
  }
  #setup_product_main .sales_tax .sales_tax_body .input_div {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  #setup_product_main .form_bodies .forms_right {
    flex-direction: column !important;
  }

  #setup_product_main .form_bodies {
    flex-direction: column;
  }

  #setup_product_main .sales_tax {
    width: 100%;
    margin: 0 !important;
  }

  #setup_product_main .form_bodies .forms_left,
  .forms_right {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  #setup_product_main .form_bodies .forms_right {
    margin-bottom: 0 !important;
  }

  #setup_product_main .form_bodies .forms_left .form_type .form_type_body {
    padding: 15px;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .radio_main {
    margin-bottom: 16px !important;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .product_detail
    > .product_name {
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .product_detail
    > .product_name
    .product_name_div {
    width: 100%;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .product_detail
    > .product_name
    .product_price_div {
    width: 100%;
    flex-direction: row;
  }

  #setup_product_main
    .form_bodies
    .forms_left
    .form_type
    .form_type_body
    .product_detail
    > .product_name
    .product_price_div
    > .input_container {
    width: 85%;
    margin: 0 !important;
  }

  #setup_product_main
    .form_type_body
    .product_detail
    > .product_name
    .product_price_div
    .inc_div {
    margin: 0 !important;
    margin-top: 15px !important;
    width: 15%;
    padding-bottom: 6px;
  }

  #setup_product_main .form_bodies > .forms_right > .discount_codes,
  .shipping_address {
    margin: 0 !important;
    margin-bottom: 20px !important;
    width: 100%;
  }

  #setup_product_main
    .form_bodies
    .forms_right
    > .discount_codes
    > .discount_codes_body,
  .shipping_address > .shipping_address_body,
  #setup_product_main .sales_tax .sales_tax_body {
    padding: 15px !important;
    padding-bottom: 20px !important;
  }

  #setup_product_main .discount_codes_body .disc_container {
    flex-direction: column;
  }

  #setup_product_main .discount_codes_body .disc_container .input_container {
    width: 100% !important;
  }

  #setup_product_main
    .form_bodies
    .forms_right
    .shipping_address
    > .shipping_address_body
    p {
    font-size: 14px !important;
  }

  #setup_product_main .pagination_outer {
    padding-top: 20px !important;
  }

  #setup_product_main .pagination_outer .next_buttn {
    padding-top: 20px;
  }

  #setup_product_main .pagination_outer .next_buttn button {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 30px;
  }

  #setup_product_main
    .discount_codes_body
    .disc_container
    .percentage_inputBefore {
    /* width: 15%; */
    width: 100% !important;
  }
  #setup_product_main .discount_codes_body .percentage_inputBefore .input_div.input_container{
    /* width: 100% !important; */
    width: 25% !important;

  }
  #setup_product_main .sales_tax .sales_tax_body .input_div {
    width: 15%;
  }
}

@media screen and (max-width: 400px) {
  #setup_product_main
    .discount_codes_body
    .disc_container
    .percentage_inputBefore {
    width: 25%;
  }
  #setup_product_main .sales_tax .sales_tax_body .input_div {
    width: 25%;
  }
}
