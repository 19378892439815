@import "../../constants/colors.css";

#form_design_main {
  /* padding: 64px 77px 75px 77px; */
  background-color: var(--page-background-color-primary);
}

#form_design_main .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#form_design_main .box_head {
  padding-bottom: 74px;
}

/* #form_design_main .box_head > h1 {
  font-weight: 600;
  font-size: 36px;
  color: var(--text-color);
  text-align: center;
  margin: 0;
  padding: 0;
} */

#form_design_main .form_design {
  display: flex;
  justify-content: space-between;
}

#form_design_main .form_design .logo_upload,
.form_style {
  width: calc(50% - 21px);
}

#form_design_main .form_design .logo_upload {
  margin-right: 21px;
}

#form_design_main .form_design .form_style .form_style_body,
#form_design_main .form_design .logo_upload .logo_upload_body {
  padding: 45px 54px 55px 49px;
  background-color: var(--white);
  box-shadow: 0px 3.8px 6.65px rgb(0 0 0 / 10%);
  border-radius: 0 0px 10px 10px;
}
#form_design_main .form_design .logo_upload .logo_upload_body {
  padding: 35px 54px 53px 44px !important;
}
#form_design_main .form_design .form_style .form_style_body {
  display: flex;
  justify-content: space-between;
  min-height: 200px;
}

#form_design_main
  .form_design
  .form_style
  .form_style_body
  .form_style_body_left {
  margin-right: 3px;
  width: 58%;
}
#form_design_main
  .form_design
  .form_style
  .form_style_body
  .form_style_body_right {
  display: flex;
  margin-left: 3px;
  width: 42%;
  justify-content: center;
}

#form_design_main
  .form_design
  .form_style
  .form_style_body
  .form_style_body_right
  .image_div
  > img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#form_design_main .form_style_body .radio_main {
  padding: 0;
}
#form_design_main .form_style_body .radio_main .radio_div {
  margin-bottom: 0;
  display: flex;
}
#form_design_main .form_style_body .radio_main .with_padding_bottom {
  margin-bottom: 22px;
  display: flex;
}
#form_design_main .form_style_body .radio_main .radio_div label {
  color: var(--text-color);
  margin-left: 5px;
  font-size: 21px;
  font-weight: 400;
}

#form_design_main .form_style_body .radio_main .radio_div input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 20px;
  height: 20px;

  border: 2px solid var(--text-color);
  transition: 0.2s all linear;
  margin-right: 5px;
  position: relative;
}

#form_design_main .form_style_body .radio_main .radio_div input:checked {
  border: 10px solid var(--text-color);
  outline: unset !important;
}

#team_contact_label {
  font-weight: bolder;
  font-size: 21px;
  margin-top: 16px;
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
  color: var(--black);
  line-height: 29px;
}

#form_design_main
  .form_design
  .logo_upload
  .logo_upload_body
  .disc_container
  .container {
  margin: 0;
  padding: 0;
}
#form_design_main
  .form_design
  .logo_upload
  .logo_upload_body
  .disc_container
  .container
  label {
  margin: 0;
  padding: 0;
  margin-bottom: 11px;
}

#form_design_main .upload_btn_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#form_design_main .upload_btn_div_right {
  max-width: 50%;
  max-height: 125px;
}
#form_design_main .upload_btn_div_right img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#form_design_main .upload_btn_div label {
  font-size: 21px;
  margin-bottom: 13px;
  color: var(--text-color);
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
}

#form_design_main .form_design .form_style {
  margin-left: 21px;
}

#form_design_main .pagination_outer {
  padding-top: 52px;
  text-align: center;
}

#form_design_main .next_buttn {
  padding-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#form_design_main .next_buttn {
  padding-top: 50px;
}

#form_design_main .next_buttn button,
#form_design_main .upload_btn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#form_design_main .upload_btn {
  margin-top: 8px;
  margin-bottom: 28px;
}

#form_design_main .upload_btn button {
  padding: 15px 48px 15px 49px;
}

#form_design_main .next_buttn .back_btn button {
  background: var(--orange-button-background);
  margin-right: 20px;
}

/* FormDesign Media Queries */

@media screen and (max-width: 1200px) {
  #form_design_main .form_design .form_style .form_style_body {
    min-height: 175px;
  }
  #form_design_main .pagination_outer {
    padding-top: 40px !important;
  }

  #form_design_main .pagination_outer .next_buttn {
    padding-top: 40px;
  }
  /* 
  #team_contact_label {
    font-size: 20px !important;
  } */
  #form_design_main .upload_btn {
    margin-top: 8px;
    margin-bottom: 25px;
  }
  #form_design_main .upload_btn button {
    padding: 10px 50px;
    font-size: 19px;
    line-height: 25px;
  }

  #form_design_main .form_style_body .radio_main .with_padding_bottom {
    margin-bottom: 18px;
  }
  #form_design_main
    .form_design
    .form_style
    .form_style_body
    .form_style_body_left {
    width: 100%;
  }

  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_right {
    display: none;
  }
  #form_design_main .form_style_body .radio_main .radio_div label {
    font-size: 19px;
  }

  #form_design_main .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 29px;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  #form_design_main .form_design .form_style .form_style_body {
    min-height: 183px;
  }
  #form_design_main .pagination_outer {
    padding-top: 30px !important;
  }

  #form_design_main .form_design .logo_upload > .logo_upload_body,
  .form_style > .form_style_body {
    padding: 20px !important;
  }
  #form_design_main
    .form_design
    .form_style
    .form_style_body
    .form_style_body_left {
    width: 65%;
  }

  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_left {
    width: 100%;
  }

  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_right {
    display: none;
  }

  #team_contact_label {
    font-size: 19px;
  }

  #form_design_main .upload_btn button {
    padding: 10px 40px;
    font-size: 17px;
    line-height: 25px;
  }

  #form_design_main
    .form_design
    .form_style
    .form_style_body
    .form_style_body_right {
    width: 35%;
  }

  #form_design_main .pagination_outer .next_buttn {
    padding-top: 30px;
  }

  #form_design_main .pagination_outer .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  #form_design_main .form_design .form_style .form_style_body {
    min-height: auto;
  }

  #form_design_main .pagination_outer {
    padding-top: 20px !important;
  }
  #form_design_main .pagination_outer .next_buttn {
    padding-top: 20px;
  }
  #form_design_main .pagination_outer .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 5px 30px;
  }
  #form_design_main .form_design {
    flex-direction: column;
  }
  #form_design_main .form_design .logo_upload,
  .form_style {
    width: 100%;
    margin: 0 !important;
  }
  #form_design_main .form_design .form_style {
    margin-top: 20px !important;
  }

  #form_design_main .form_design .logo_upload > .logo_upload_body,
  .form_style > .form_style_body {
    padding: 15px !important;
    padding-bottom: 25px !important;
  }

  #form_design_main
    .form_design
    .logo_upload
    > .logo_upload_body
    > .disc_container
    > .upload_btn_div
    label {
    font-size: 14px !important;
  }

  #form_design_main
    .form_design
    .logo_upload
    > .logo_upload_body
    > .disc_container
    > .upload_btn_div
    > .upload_btn {
    margin: 0;
  }
  #form_design_main
    .form_design
    .logo_upload
    > .logo_upload_body
    > .disc_container
    > .upload_btn_div
    > .upload_btn
    > button {
    font-size: 14px;
    line-height: 25px;
    padding: 5px 30px;
  }
  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_left {
    width: 100%;
  }
  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_left
    span {
    font-size: 14px;
    margin-top: 8px;
  }
  #form_design_main
    .form_design
    .form_style
    > .form_style_body
    > .form_style_body_right {
    display: none;
  }

  #form_design_main .form_style_body .radio_main .radio_div input:checked {
    border-width: 8px;
  }
}
