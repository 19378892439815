@import "../constants/colors.css";

.pagination ul {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0;
}
.pagination ul li {
  list-style: none;
  width: 20px;
  height: 20px;
  background: var(--pagination-li-background);
  border-radius: 50%;
  margin-right: 10px;
}
.pagination ul .active {
  background: var(--text-color);
}
.pagination p {
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  max-width: 300px;
  margin: 0px auto;
  margin-bottom: 25px;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .pagination p {
    font-size: 27px;
    line-height: 27px;
    max-width: 275px;
    margin-bottom: 22px;
  }

  .pagination ul li {
    width: 15px;
    height: 15px;
    margin-right: 7px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pagination p {
    font-size: 23px;
    line-height: 27px;
    max-width: 250px;
    margin-bottom: 18px;
  }

  .pagination ul li {
    width: 15px;
    height: 15px;
    margin-right: 7px;
  }
}


@media screen and (max-width: 767px) {
  .pagination p {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    max-width: 200px;
    margin-bottom: 12px;
  }

  .pagination ul li {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}