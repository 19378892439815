.percentage_component {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .percentage_component {
    font-size: 19px;
    font-weight: 400;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .percentage_component {
    font-size: 17px;
    font-weight: 400;
  }
}

@media screen and (max-width: 767px) {
  .percentage_component {
    font-size: 14px;
    font-weight: 400;
  }
}
