@import "../../constants/colors.css";

#setup_payment_main {
  /* padding: 64px 78px 93px 77px; */
  background-color: var(--page-background-color-primary);
}

#setup_payment_main .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#setup_payment_main .form_head {
  display: flex;
  justify-content: center;
}

/* #setup_payment_main .form_head > h1 {
  font-weight: 600;
  font-size: 36px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0px;
  line-height: 29px;
} */

#setup_payment_main .form_bodies {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-top: 25px; */
}

#setup_payment_main .form_bodies .forms_left,
.forms_right {
  display: flex;
  flex-direction: column;
  width: calc(50% - 21px);
}

#setup_payment_main .form_bodies .forms_left .payment_method,
#setup_payment_main .form_bodies .forms_right .payment_frequency {
  background-color: var(--white);
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
  border-radius: 10px;
  height: 100%;
}

.sales_tax,
.shipping_address {
  margin-top: 30px;
}

.setup_payment_main
  .form_bodies
  .forms_left
  .payment_method
  .payment_method_body,
.setup_payment_main
  .form_bodies
  .forms_right
  .payment_frequency
  .payment_frequency_body {
  padding: 40px 20px;
  border-radius: 10px;
  color: var(--white);
}

#setup_payment_main .container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

#setup_payment_main .container select {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

#setup_payment_main .container select:focus {
  color: var(--input-focus-color);
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus-2);
  outline: 0;
}

#setup_payment_main .save_btn {
  padding-top: 50px;
}

#setup_payment_main .save_btn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#setup_payment_main .payment_method_body .radio_main {
  padding: 10px 15px;
}

#setup_payment_main .disc_container .installments {
  padding: 20px 0;
  display: flex;
  width: 80%;
  margin-top: 52px;
  justify-content: space-between;
  margin-left: -5px;
}

#setup_payment_main .payment_method_body .radio_main .radio_div,
#setup_payment_main .payment_frequency_body .radio_main .radio_div {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}
#setup_payment_main .payment_frequency_body .radio_main .radio_div {
  margin-bottom: 0;
  align-items: center;
}
#setup_payment_main .payment_frequency_body,
.payment_method_body {
  padding: 35px 37px 44px 43px;
}

#setup_payment_main .payment_frequency_body p {
  color: var(--text-color);
  font-size: 21px;
  padding: 0;
  padding-bottom: 8px;
  font-weight: 400;
}

/* #setup_payment_main .pagination {
  text-align: center;
} */
#setup_payment_main .pagination_outer {
  margin: 64px 0 0px 0;
  text-align: center;
}

#setup_payment_main .next_buttn {
  padding-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#setup_payment_main .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}
#setup_payment_main .next_buttn .back_btn button {
  background: var(--orange-button-background);
  margin-right: 20px;
}

/* setupPayment Media Queries */

@media screen and (max-width: 1200px) {
  #setup_payment_main .form_bodies .forms_left,
  .forms_right {
    width: calc(50% - 21px) !important;
  }

  #setup_payment_main .form_bodies .forms_right {
    margin: 0 !important;
  }

  #setup_payment_main .pagination_outer .next_buttn {
    padding-top: 40px;
  }

  #setup_payment_main .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 25px;
    padding: 10px 50px;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body,
  .forms_right .payment_frequency > .payment_frequency_body {
    padding: 30px !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main {
    padding: 0 !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main
    .radio_div {
    margin-bottom: 11px !important;
  }

  #setup_payment_main .disc_container .installments {
    padding: 0 !important;
    /* margin: 0; */
    margin-top: 15px !important;
  }
  #setup_payment_main .pagination_outer {
    margin-top: 40px;
  }
  #setup_payment_main .pagination_outer .next_buttn {
    padding: 0;
    padding-top: 40px;
  }
  #setup_payment_main .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 25px;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  #setup_payment_main .form_bodies .forms_left,
  .forms_right {
    width: calc(50% - 21px) !important;
  }

  #setup_payment_main .form_bodies .forms_right {
    margin: 0 !important;
  }

  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body,
  .forms_right .payment_frequency > .payment_frequency_body {
    padding: 20px !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main {
    padding: 0 !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main
    .radio_div {
    margin-bottom: 11px !important;
  }

  #setup_payment_main .disc_container .installments {
    padding: 0 !important;
    /* margin: 0; */
    margin-top: 15px !important;
  }
  #setup_payment_main .pagination_outer {
    margin-top: 30px;
  }
  #setup_payment_main .pagination_outer .next_buttn {
    padding: 0;
    padding-top: 30px;
  }
  #setup_payment_main .pagination_outer .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  #setup_payment_main .pagination_outer .next_buttn {
    padding-top: 20px;
  }

  #setup_payment_main .pagination_outer .next_buttn button {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 30px;
  }

  #setup_payment_main .form_bodies {
    flex-direction: column;
  }

  #setup_payment_main .form_bodies .forms_left,
  .forms_right {
    width: 100% !important;
  }

  #setup_payment_main .form_bodies .forms_right {
    margin: 0 !important;
    margin-top: 20px !important;
  }

  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body,
  .forms_right .payment_frequency > .payment_frequency_body {
    padding: 15px !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main {
    padding: 0 !important;
  }
  #setup_payment_main
    .form_bodies
    .forms_left
    .payment_method
    > .payment_method_body
    .radio_main
    .radio_div {
    margin-bottom: 11px !important;
  }

  #setup_payment_main .disc_container .installments {
    padding: 0 !important;
    /* margin: 0; */
    margin-top: 15px !important;
  }
  #setup_payment_main .pagination_outer {
    margin-top: 20px;
  }
}
