@import "../../constants/colors.css";

#create_form_main {
  /* padding: 40px; */
  background-color: var(--page-background-color-primary);
}

.invalid-error{
  color: var(--error);
  font-size: 11px;
}

#create_form_main .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#create_form_main .form_head {
  display: flex;
  justify-content: center;
}

#create_form_main .text_info{
  font-size: 14px;
  margin-top: 5px;
  color: var(--black);
}

/* #create_form_main .form_head > h1 {
  font-weight: 600;
  font-size: 36px;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 50px;
  margin-top: 25px;
  line-height: 29px;
} */

#create_form_main .form_bodies {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-top: 25px; */
}

#create_form_main .form_bodies .form_name,
.form_currency {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  border-radius: 10px;
  box-shadow: 0px 3.8px 6.65px var(--box-shadow-1);
}

#create_form_main .form_bodies .form_name {
  margin-right: 20px;
}

#create_form_main .form_bodies .form_currency {
  margin-left: 20px;
}
#create_form_main .form_bodies .form_currency img{
  max-width: 37px;
}

#create_form_main .form_bodies .form_name > .name_head,
.form_currency > .currency_head {
  background: linear-gradient(
    270deg,
    var(--box-header-linear-primary) 0.11%,
    var(--box-header-linear-secondary) 99.91%
  );
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  color: var(--white);
}

#create_form_main .form_bodies .form_name > .name_head > img,
.form_currency > .currency_head > img {
  width: 25px;
  margin-left: 10px;
  margin-right: 10px;
  height: 25px;
}

#create_form_main .form_bodies .form_name > .name_body,
.form_currency > .currency_body {
  padding-bottom: 20px;
  border-radius: 10px;
  color: var(--white);
  padding: 20px 40px 40px 40px;
}

#create_form_main .select_container label {
  display: block;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: var(--text-color);
  margin-bottom: 10px;
  margin-top: 30px;
}

#create_form_main .select_container select {
  display: block;
  width: 100%;
  background: var(--input-bg);
  border: 2px solid var(--text-color);
  border-radius: 8px;
  padding: 5px 10px;
  min-height: 45px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

#create_form_main .select_container select > option {
  font-size: 21px;
  line-height: 29px;
  font-weight: 400;
}

#create_form_main .select_container select:focus {
  color: var(--input-focus-color);
  box-shadow: 0px 0px 3px 0px var(--box-shadow-focus-2);
  outline: 0;
}

#create_form_main .save_btn {
  padding: 70px 0px 0px 0px;
}

#create_form_main .save_btn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}
#create_form_main .page_container{
  padding: 64px 78px 80px 77px;
}
/* createForm Media Queries */

@media screen and (max-width:1200px){
  #create_form_main .form_bodies .form_name > .name_body,
  .form_currency > .currency_body {
    padding: 15px;
    }

  #create_form_main .select_container label {
    font-size: 19px !important;
    margin: 0;
  }
  #create_form_main .select_container {
    padding: 0;
  }

  #create_form_main .select_container label {
    margin-top: 9px !important;
    font-size: 19px !important;
    margin-bottom: 9px !important;
  }

  #create_form_main .select_container select {
    padding: 0;
    font-size: 19px;
    font-weight: 400;
    min-height: 40px;
  }

  #create_form_main .select_container select option {
    font-size: 19px;
    font-weight: 400;
  }

  #create_form_main .save_btn {
    padding: 0;
    padding-top: 40px;
  }

  #create_form_main .save_btn button {
    font-size: 19px;
    line-height: 29px;
    padding: 10px 50px ;
  }
}

@media screen and (max-width: 1024px) {
  #create_form_main .form_bodies .form_name > .name_body,
  .form_currency > .currency_body {
    padding: 20px;
  }

  #create_form_main .select_container label {
    font-size: 17px !important;
    margin: 0;
  }
  #create_form_main .select_container {
    padding: 0;
  }

  #create_form_main .select_container label {
    margin-top: 7px !important;
    font-size: 17px !important;
    margin-bottom: 7px !important;
  }

  #create_form_main .select_container select {
    padding: 0;
    font-size: 17px;
    font-weight: 400;
    min-height: 35px;
  }

  #create_form_main .select_container select option {
    font-size: 17px;
    font-weight: 400;
  }

  #create_form_main .save_btn {
    padding: 0;
    padding-top: 30px;
  }

  #create_form_main .save_btn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  #create_form_main .form_bodies {
    flex-direction: column;
    align-items: center;
  }

  #create_form_main .form_bodies .form_name,
  .form_currency {
    margin: 0 !important;
    width: 100%;
  }
  #create_form_main .form_bodies .form_name > .name_body,
  .form_currency > .currency_body {
    padding: 15px;
  }
  #create_form_main .form_bodies .form_name {
    margin-bottom: 20px !important;
  }
  #create_form_main .select_container {
    padding: 0;
  }

  #create_form_main .select_container label {
    font-size: 15px !important;
    margin: 0;
  }

  #create_form_main .select_container select {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    min-height: 30px;
  }

  #create_form_main .select_container select option {
    font-size: 14px;
    font-weight: 400;
  }
  #create_form_main .save_btn {
    padding: 0;
    padding-top: 20px;
  }
  #create_form_main .save_btn button {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 30px;
  }
  #create_form_main .page_container{
    padding: 25px 15px;
  }
}
