@import "../constants/colors.css";

.checkbox_div {
  align-items: center;
  display: flex;
  margin: 0;
}

.with_padding_bottom {
  margin-bottom: 22px;
}
.checkbox_div .check_div {
  align-self: flex-start;
}

.checkbox_div .check_div input[type="checkbox"] {
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid var(--text-color);
  transition: 0.2s all linear;
  position: relative;
  margin-top: 6px;
}

.checkbox_div > label {
  color: var(--text-color);
  margin-left: 5px;
  margin-top: -3px;
  font-size: 21px;
  font-weight: 600;
}

.checkbox_main .checkbox_div input:checked {
  border: 10px solid var(--text-color);
  outline: unset !important;
}

@media screen and (min-width: 102px) and (max-width: 1200px) {
  .checkbox_div > label {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .checkbox_div > label {
    font-size: 19px;
  }
}

@media screen and (max-width: 767px) {
  .checkbox_div > label {
    font-size: 16px;
  }
}
