@import "../constants/colors.css";

header {
  width: 100%;
  padding-bottom: 0px;
  position: relative;
}

.container {
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0px auto;
}

header .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header nav {
  display: flex;
}

header nav ul {
  display: flex;
  align-items: center;
}

header nav ul li {
  list-style: none;
}

header nav ul li a {
  text-decoration: none;
  color: var(--white);
  font-size: 21px;
  line-height: 29px;
  font-weight: 600;
}

header nav ul.first_ul li {
  padding: 5px 30px;
  margin-right: 10px;
}

header nav .button_ul ul li a {
  color: var(--white);
}

header nav .button_ul ul {
  background: var(--ul-background);
  border-radius: 30px;
  padding: 10px 20px;
}

header .dashbord {
  width: 100%;
  background: rgba(52, 101, 106, 0.25);
}

header .dashboard_ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-top: 0px;
}

header .dashboard_ul li {
  list-style: none;
}

header .dashboard_ul li a {
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: var(--text-color);
}

header .dashboard_ul li {
  padding: 15px 10px;
}

.active_header_li {
  /* padding: 15px 10px; */
  background: var(--active-nav);
  /* margin-left: 5px; */
}
/* header .dashboard_ul li:nth-child(2) {
  padding: 15px 10px;
  background: var(--active-nav);
  margin-left: 5px;
} */

header .dashboard_ul li:last-child {
  /* margin-left: 80px; */
}
/* header .dashboard_ul li:last-child a {
  margin-top: -15px;
} */

header .header_main {
  background: linear-gradient(
    270deg,
    var(--linear-primary) 0.69%,
    var(--linear-secondary) 100%
  );
}

header .header .mobile_nav {
  display: none;
}

header .header .log_sec a {
  display: block;
}

.lost_password_header {
  background-color: var(--text-color);
  padding: 15px 44px !important;
}
.lost_password_header h1 {
  color: var(--white);
  font-weight: 500;
  text-align: left !important;
  font-size: 21px !important;
  margin: 0 auto !important;
}

/* ///////////////////////////////////////////Header meadia quarry started//////////////////////////////////////////////// */

@media screen and (max-width: 1200px) {
  header nav .button_ul ul {
    padding: 10px 15px;
  }

  header nav ul li a {
    font-size: 20px;
    line-height: 26px;
  }

  header nav ul.first_ul li {
    padding: 5px 20px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 1024px) {
  header .header .log_sec {
    max-width: 260px;
  }

  header .header .log_sec img {
    width: 100%;
  }

  header nav {
    margin-left: auto;
  }
}

@media screen and (max-width: 991px) {
  header .header .mobile_nav {
    display: block;
  }

  header .header .mobile_nav .icon {
    max-width: 80px;
    height: 35px;
    cursor: pointer;
    display: flex;
  }

  header .header .mobile_nav .icon img {
    width: 100%;
    height: 100%;
  }

  header .header .desk_nav {
    display: none;
  }

  header .header .mobile_nav .hamburger {
    padding: 20px 0px;
    transition: all 0.3s;
  }

  header .header .mobile_nav .hamburger_content {
    position: absolute;
    left: 50%;
    right: 0px;
    top: 0%;
    background-color: rgba(52, 101, 106, 0.5);
    backdrop-filter: blur(10px);
    padding: 10px 15px;
    z-index: 999;
    border-radius: 10px 0px 10px 10px;
  }

  header .header .mobile_nav ul {
    display: block;
  }

  header .header .mobile_nav .first_ul {
    padding-left: 0px;
    border-bottom: 1px solid var(--white);
    padding-bottom: 20px;
    transition: all 0.3s;
  }

  header .header .mobile_nav .first_ul li a:hover {
    color: var(--linear-primary);
  }

  header .header .mobile_nav .first_ul .crossImg {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    transition: all 0.3s;
  }

  header .header .mobile_nav .first_ul .crossImg img {
    width: 20px !important;
    height: 20px;
  }

  header .header .mobile_nav .button_ul {
    width: 100%;
    padding: 15px 0px;
    text-align: center;
  }

  header .header .mobile_nav .button_ul button {
    border: none;
    color: var(--white);
    padding: 10px 20px;
    background: var(--ul-background);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  header .dashboard_ul li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  header .header .mobile_nav .hamburger_content {
    left: 0;
    padding-top: 0px;
    border-radius: 0;
  }
  header .header .log_sec {
    max-width: 190px;
    margin-left: -15px;
  }
  header .header .mobile_nav .hamburger {
    padding: 10px 0px;
  }
  header .header .mobile_nav .icon {
    height: 30px;
  }
  header .header .mobile_nav .button_ul {
    padding: 10px 0px;
  }
  header .header .mobile_nav .first_ul .crossImg {
    padding-right: 5px;
  }
  header .dashboard_ul {
    padding-left: 0px;
    align-items: center;
    justify-content: start;
  }
  header .dashboard_ul li {
    padding: 10px 5px;
    font-size: 14px;
  }
  header .dashboard_ul li a {
    font-size: 14px;
  }
  header .dashboard_ul li:last-child {
    margin-left: 0px;
    margin-top: 0px;
  }
  .lost_password_header {
    background-color: #34656a;
    padding: 10px 18px !important;
  }
  .lost_password_header h1 {
    font-size: 18px !important;
  }
  header .header {
    padding: 10px 0px;
  }
  header .dashboard_ul li:nth-child(2) {
    padding: 10px;
  }
  header .dashboard_ul li:last-child a {
    margin-top: unset;
  }
}
