@import "../../constants/colors.css";

#payment_process .crm_sec {
  /* padding: 64px 0 75px 0; */
}
#payment_process .crm_sec .crm_main .crm_body {
  background-color: transparent !important;
}
.payment_processor .crm_body {
  /* padding: 72px 0 64px 0; */
  padding: 0 !important;
}
#payment_process .Paymentedit_sec .crm_main {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

#payment_process .content_box {
  padding-top: 0px;
  padding-bottom: 0px;
  row-gap: 30px;
}

#payment_process .content_box .content2 img {
  left: 20px;
}

#payment_process .next_buttn {
  padding-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#payment_process .next_buttn button {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: var(--white);
  padding: 10px 65px;
  border-radius: 30px;
  cursor: pointer;
  background: var(--text-color);
  border: none;
}

#payment_process .next_buttn .back_btn button {
  background: var(--orange-button-background);
  margin-right: 20px;
}

/* crm system page css :start/////////////////////////////////////// */

.crmsystem .clic_section {
  width: 100%;
  text-align: center;
  padding-top: 50px;
}

.crmsystem .clic_section a {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--text-color);
}
.crmsystem .clic_section a span {
  font-weight: 500;
}

.crmsystem .content_no_crm{
  display: flex;
  align-items: center;
  justify-content: center;
}
.crmsystem .content_no_crm p{
  font-size: 18px;
  color: var(--text-color);
  text-align: center;
  font-weight: 600;
}

.crmsystem .content_box .content1 img {
  left: 28%;
}
.crmsystem .content_box .content2 img {
  left: 45px;
}
.crmsystem .content_box .content3 img {
  left: 18%;
}
.crmsystem .content_box .content4 img:first-child {
  left: 25%;
  top: 34%;
}
.crmsystem .content_box .content4 img:last-child {
  left: 20%;
  top: 76%;
}
.crmsystem .content_box .content5 img:first-child {
  left: 31%;
  top: 40%;
}
.crmsystem .content_box .content5 img:last-child {
  left: 19%;
  top: 75%;
}

#payment_process .pagination_outer {
  text-align: center;
  padding-top: 64px;
}

@media screen and (max-width: 1200px) {
  #payment_process .pagination_outer {
    padding-top: 40px !important;
  }

  #payment_process .pagination_outer .next_buttn {
    padding-top: 40px;
  }

  #payment_process .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 25px;
    padding: 10px 50px;
  }

  .crmsystem .clic_section {
    padding-top: 40px;
  }

  #payment_process .clic_section > a {
    font-size: 20px;
  }

  #payment_process .clic_section > a > span {
    font-size: 20px;
    display: block;
  }

  #payment_process .pagination_outer .next_buttn {
    padding-top: 40px;
  }
  #payment_process .pagination_outer .next_buttn button {
    font-size: 19px;
    line-height: 29px;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 1024px) {
  #payment_process .pagination_outer {
    padding-top: 30px !important;
  }
  .crmsystem .clic_section {
    padding-top: 30px;
  }

  #payment_process .clic_section > a {
    font-size: 20px;
  }

  #payment_process .clic_section > a > span {
    font-size: 20px;
    display: block;
  }
  #payment_process .content_box .content5{
    flex-direction: column;
  }
  #payment_process .pagination_outer .next_buttn {
    padding-top: 30px;
  }

  #payment_process .pagination_outer .next_buttn button {
    font-size: 17px;
    line-height: 25px;
    padding: 10px 40px;
  }

}




@media screen and (max-width: 767px) {
  #payment_process .pagination_outer {
    padding-top: 20px !important;
  }

  #payment_process .content_box .content {
    height: 150px;
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .crmsystem .clic_section {
    padding-top: 20px;
  }

  #payment_process .clic_section > a {
    font-size: 18px;
  }

  #payment_process .clic_section > a > span {
    font-size: 18 px;
    display: block;
  }

  #payment_process .content_box .content img {
    left: unset;
  }

  #payment_process .content_box .content img:nth-child(2) {
    top: 85%;
  }

  #payment_process .pagination_outer .next_buttn {
    padding-top: 20px;
  }

  #payment_process .pagination_outer .next_buttn button {
    font-size: 16px;
    line-height: 25px;
    padding: 5px 30px;
  }
}
